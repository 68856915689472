import API from 'services/api';

export const createProject = async (data: any) => {
  return await API.post('/project', data);
};

export const startProject = async (id: string) => {
  return await API.put(`/project/start/${id}`);
};

export const editProject = async (id: number, data: any) => {
  return await API.put(`/project/${id}`, data);
};

export const getProject = async (id) => {
  return await API.get(`/project/${id}`);
};

export const completeProject = async (id: number, data: any) => {
  return await API.put(`/project/complete/${id}`, data);
};

export const excludeProject = async (id: number, data: any) => {
  return await API.put(`/project/${id}/exclude`, data);
};

export const getAllProject = async (params = {}) => {
  return await API.get(`/project/all`, { params });
};

export const getProjects = async (props) => {
  const { limit = 20, page = 0, sort, name } = props;
  return await API.get('/project', {
    params: { limit, page, sort, name },
  });
};

export const getDraftProjects = async (props) => {
  const { limit = 20, page = 0, sort, name, filter } = props;
  return await API.get('/project', {
    params: { limit, page, sort, name, status: 'draft', filter },
  });
};

export const getPendingProjects = async (props) => {
  const { limit = 20, page = 0, sort, name, filter } = props;
  return await API.get('/project', {
    params: { limit, page, sort, name, status: 'inProgress', filter },
  });
};

export const getCompletedProjects = async (props) => {
  const { limit = 20, page = 0, sort, name, filter } = props;
  return await API.get('/project', {
    params: { limit, page, sort, name, status: 'completed', filter },
  });
};

export const getExcludedProjects = async (props) => {
  const { limit = 20, page = 0, sort, name, filter } = props;
  return await API.get('/project', {
    params: { limit, page, sort, name, status: 'excluded', filter },
  });
};

export const canCreateProject = async () => {
  return await API.get(`/project/cancreate`);
};

export const addTemplate = async ({ id, testId, data }) => {
  return await API.put(`/project/${id}/add-template/${testId}`, data);
};

export const getProjectPlans = async (props) => {
  const { limit = 10, page = 0, sort, name, filter = '' } = props;
  return await API.get('/project/plans', {
    params: { limit, page, sort, filter },
  });
};

export const getProjectDocuments = async (id) => {
  return await API.get(`/project/${id}/documents`);
};

export const completeDocument = async (projectId, documentId, data) => {
  return await API.put(`project/${projectId}/document/${documentId}/complete`, {
    ...data,
    completed: true,
  });
};

export const updateDocument = async (projectId, documentId, data) => {
  return await API.put(`project/${projectId}/document/${documentId}`, data);
};

export const removeProjectPlan = async (ids) => {
  return await API.delete(`/project/plans`, {
    data: {
      ids,
    },
  });
};
