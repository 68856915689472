import React, { useState } from 'react';
import {
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import { InputError } from '../../../components';
import { useTranslation } from 'react-i18next';

export enum TemplateDataView {
  TABLE = 0,
  CHART = 1,
}

const SaveTemplate = (props) => {
  const [dataView, setDataView] = useState(TemplateDataView.TABLE);
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setDataView(event.target.value as TemplateDataView);
  };
  const [value, setInputValue] = useState('');
  const { control, register, setValue, formState } = useFormContext();

  const { t } = props;
  return (
    <Grid item container xs={12}>
      <InputLabel htmlFor={'exclude-result-select'}>
        {t('saveTemplate_title')}
      </InputLabel>
      <Controller
        name={'dataView'}
        defaultValue={TemplateDataView.TABLE}
        rules={{ required: true }}
        control={control}
        render={({ field: { onChange, value } }) => (
          <Grid item container xs={12} alignItems={'center'}>
            <Select
              labelId="exclude-result-label"
              id="exclude-result-select"
              value={dataView}
              onChange={(event, value) => {
                handleChange(event);
                setValue('dataView', event.target.value);
              }}
              label={t('saveTemplate_modalTitle')}
              fullWidth={true}
            >
              <MenuItem value={TemplateDataView.TABLE}>
                {t('saveTemplate_tableOption')}
              </MenuItem>
              <MenuItem value={TemplateDataView.CHART}>
                {t('saveTemplate_chartOption')}
              </MenuItem>
            </Select>
            {formState.errors['reason'] && (
              <InputError message={'saveTemplate_required'} />
            )}
          </Grid>
        )}
      />
      <InputLabel
        htmlFor={'exclude-reason-comment'}
        style={{ marginTop: '30px' }}
      >
        {t('saveTemplate_templateName')}
      </InputLabel>
      <Controller
        name={'name'}
        rules={{ required: true }}
        control={control}
        defaultValue={''}
        render={({ field: { onChange, value } }) => {
          const { ref, ...rest } = register('name', {
            required: true,
          });
          return (
            <TextField
              onChange={(event) => {
                setInputValue(event.target.value);
                setValue('name', event.target.value);
              }}
              inputProps={{
                name: 'name',
                defaultValue: '',
                maxLength: 255,
              }}
              margin={'dense'}
              fullWidth
              id={'exclude-reason-comment'}
              variant="standard"
            />
          );
        }}
      />
      {formState.errors['name'] && (
        <InputError message={'saveTemplate_required'} />
      )}
      <span style={{ alignSelf: 'flex-end', marginLeft: 'auto' }}>
        {value.length} / 255
      </span>
    </Grid>
  );
};

export default SaveTemplate;
