import React from 'react';
import { Grid } from '@material-ui/core';
import StepCounter from '../StepCounter';
import './ProjectsStepCounter.scss';
import { useTranslation } from 'react-i18next';
const ProjectsStepCounter = () => {
  const { t } = useTranslation('project');
  const steps = [t('project'), t('data'), t('results')];
  const checkActiveStep = () => {
    const currentLocation = window.location.pathname;
    const projectPathRegex = new RegExp(`project(\/[0-9a-fA-F]{24}$)`);
    const projectDataRegex = new RegExp(`project\/[0-9a-fA-F]{24}/data$`);
    const projectResultsRegex = new RegExp(`project\/[0-9a-fA-F]{24}/results$`);

    if (currentLocation.match(projectPathRegex)) {
      return 0;
    }
    if (currentLocation.match(projectDataRegex)) {
      return 1;
    }
    if (currentLocation.match(projectResultsRegex)) {
      return 2;
    }
    return 0;
  };
  return (
    <Grid container className={'projects-step-counter'}>
      <StepCounter steps={steps} checkActiveStep={checkActiveStep} />
    </Grid>
  );
};

export default ProjectsStepCounter;
