import React from 'react';
import { Grid, Step, StepLabel, Stepper, withStyles } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';

interface IStepCounter {
  steps: string[];
  checkActiveStep(): number;
}

const StepCounter = ({ steps = [], checkActiveStep }: IStepCounter) => {
  return (
    <Grid style={{ width: '100%' }}>
      <Stepper
        activeStep={checkActiveStep()}
        style={{ backgroundColor: 'transparent' }}
      >
        {steps.map((step) => (
          <Step key={step} completed={false}>
            <StepLabel>{step}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Grid>
  );
};

export default StepCounter;
