import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import {
  PROJECTDATA_DESELECTALLRESULTS,
  PROJECTDATA_SELECTALLRESULTS,
} from '../../ProjectDataTypes';
import { PageTitle } from '../../../../components';

const ProjectDataNavigation = ({
  projectData,
  customProjectId,
  goBackToProjectEdit,
  t,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  return (
    <PageTitle title={`${t('testResultsTitle')}: ${customProjectId}`}>
      <div>
        <Button onClick={goBackToProjectEdit} color="primary">
          <Typography variant={'body2'}>{t('project:goBack')}</Typography>
        </Button>
        {projectData.selectedResults.length !==
        projectData.resultTables.length ? (
          <Button
            color="primary"
            onClick={() =>
              dispatch({
                type: PROJECTDATA_SELECTALLRESULTS,
              })
            }
          >
            {t('selectAllTests')}
          </Button>
        ) : (
          <Button
            color="primary"
            onClick={() =>
              dispatch({
                type: PROJECTDATA_DESELECTALLRESULTS,
              })
            }
          >
            {t('deselectAllTests')}
          </Button>
        )}
        <Button
          color={'primary'}
          variant={'contained'}
          disabled={!projectData?.selectedResults?.length}
          onClick={() => {
            history.push(location.pathname.replace('/data', '/results'));
          }}
        >
          {t('viewResults')}
        </Button>
      </div>
    </PageTitle>
  );
};

export default ProjectDataNavigation;
