import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Grid,
  IconButton,
  SvgIcon,
  Switch,
  Typography,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import {
  clearPreviewData,
  fetchResultForProject,
  fetchResultTable,
  saveTemplateAction,
} from './FastPreviewActions';
import './FastPreview.scss';
import {
  dateTimeHelper,
  transformHeadersForResultsTable,
  transformHeadersForMetadataTable,
  getAllHeaders,
  findPretreatmentNameForTest,
} from '../../utils/transform-helpers';
import { DataTable } from '../../components/DataTable/DataTable';
import ResultCommentModal from '../ProjectData/components/Modals/ResultCommentModal';
import TestManualData from 'containers/TestResults/components/TestManualData/TestManualData';
import { postCommentAction } from 'containers/ProjectData/ProjectDataActions';

import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import SaveTemplate, { TemplateDataView } from './components/SaveTemplate';
import ProjectDataModal from '../ProjectData/components/Modals/ProjectDataModal';
import { useForm } from 'react-hook-form';
import { DataChart } from 'components';
import { UploadFiles } from 'components/UploadFiles/UploadFiles';
import { deleteFile, getUploadLink, uploadFiles } from 'repository/testResult';
import { asyncForEach } from 'utils/util-functions';
import { createUploadPathForResult } from 'utils/upload-helpers';
import { useTranslation } from 'react-i18next';

const FastPreview = (props) => {
  const {
    projectId,
    tableId,
    resultTableId,
    resultName,
    editMode = false,
    closeModal = () => {},
  } = props;
  const dispatch = useDispatch();

  const { result, resultTable } = useSelector(
    (state: any) => state.fastPreview,
  );

  const { t } = useTranslation('fastPreview');

  const { test, pretreatments, project } = result;
  const { metadata, resultTableRows, manualInputs } = resultTable;

  const [resultHeaders, setResultHeaders] = useState([] as any);
  const [metadataHeaders, setMetadataHeaders] = useState([] as any);

  const [resultData, setResultData] = useState([] as any);
  const [metadataData, setMetadataData] = useState([] as any);

  const [hiddenMetadataColumns, setHiddenMetadataColumns] = useState([] as any);
  const [hiddenResultColumns, setHiddenResultColumns] = useState([] as any);
  const [comment, setComment] = useState('' as any);
  const [clearComment, setClearComment] = useState(false as any);

  const [viewMode, setViewMode] = useState(TemplateDataView.TABLE);

  const [templateModalOpen, setTemplateModalOpen] = useState(false);

  const methods = useForm();

  const tableRowResults: any = [];

  useEffect(() => {
    if (!!tableId && !!resultTableId) {
      dispatch(
        fetchResultForProject({ projectId: projectId, resultId: tableId }),
      );
      dispatch(fetchResultTable({ resultId: tableId, resultTableId }));
    }

    return () => {
      dispatch(clearPreviewData());
    };
  }, [tableId, resultTableId]);

  useEffect(() => {
    if (resultTableRows?.length) {
      resultTableRows.map((table) => tableRowResults.push(table.results));
      setResultData([...tableRowResults]);
    }

    if (resultTable?.headers?.length) {
      setResultHeaders([
        ...transformHeadersForResultsTable(resultTable?.headers),
      ]);
    }

    if (!!metadata) {
      setMetadataHeaders([
        ...transformHeadersForMetadataTable(Object.keys(metadata)),
      ]);
      setMetadataData([{ ...metadata }]);
    }
  }, [result, resultTable]);

  const memoizedResultColumns = useMemo(() => resultHeaders, [resultHeaders]);
  const memoizedResultData = useMemo(() => resultData, [resultData]);

  const memoizedMetadataColumns = useMemo(
    () => metadataHeaders,
    [metadataHeaders],
  );

  const memoizedMetadataData = useMemo(() => metadataData, [metadataData]);

  const submitTemplate = async (data) => {
    methods.handleSubmit(data);
    closeModal();
  };

  const saveTemplate = (data) => {
    dispatch(
      saveTemplateAction({
        id: result?.project?._id,
        testId: result?.test?._id,
        data: {
          name: data.name,
          dataView: data.dataView,
          entity: result?.device?._id,
          config: {
            resultColumns: getAllHeaders(resultHeaders),
            hiddenResultColumns: hiddenResultColumns,
            metadataColumns: getAllHeaders(metadataHeaders),
            hiddenMetadataColumns: hiddenMetadataColumns,
          },
        },
      }),
    );
  };

  const preDefinedPath = createUploadPathForResult(result);
  const uploadFilesMethod = async (files) => {
    let formData = new FormData();

    files.forEach((file) => {
      formData.append('files', file);
    });
    try {
      await uploadFiles('/result/' + result._id + '/files', formData);
      return [];
    } catch (e) {
      return files;
    }
  };

  const deleteFileMethod = async (fileName) => {
    return await deleteFile(result._id, fileName);
  };

  return (
    <>
      {editMode ? (
        <Grid
          container
          justify={'space-between'}
          alignItems={'center'}
          className="modal-title"
        >
          <Grid
            item
            xs={3}
            container
            alignContent="center"
            justify="space-between"
            alignItems="center"
          >
            <Grid item xs={4}>
              <Typography variant={'h4'}>{t('configurator')}</Typography>
            </Grid>
            <Grid item xs={8}>
              <Button
                onClick={() => setTemplateModalOpen(true)}
                color="primary"
              >
                <SvgIcon component={SaveIcon} />
                {t('saveTemplate_title')}
              </Button>
            </Grid>
          </Grid>
          <Grid item>
            <IconButton onClick={() => closeModal()}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      ) : null}
      <Grid container className="fast-preview">
        <Grid container item xs={12}>
          {editMode ? (
            <Typography variant="h4" className="test-info">{`${t(
              'testInformation',
            )}: ${resultName}`}</Typography>
          ) : null}
          {test && (
            <div className="metadata">
              <div className="metadata-item">
                <Grid item xs={12}>
                  <Typography variant="h5" className="metadata-label">
                    {t('type')}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" className="metadata-value">
                    {t(`testType_${test?.testType?.type}`, { ns: 'project' })}
                  </Typography>
                </Grid>
              </div>
              <div className="metadata-item">
                <Grid item xs={12}>
                  <Typography variant="h5" className="metadata-label">
                    {t('subtype')}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" className="metadata-value">
                    {t(`testSubType_${test?.testSubType?.type}`, {
                      ns: 'project',
                    })}
                  </Typography>
                </Grid>
              </div>

              {test?.testSubType.fields.map((field) => (
                <div className="metadata-item">
                  <Grid item xs={12}>
                    <Typography variant="h5" className="metadata-label">
                      {t(`project:tests_testForm_${field.fieldName}`)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1" className="metadata-value">
                      {field.fieldName === 'skzAutoclav' &&
                      pretreatments?.length
                        ? findPretreatmentNameForTest(test, pretreatments)
                        : dateTimeHelper(test[field.fieldName], field.type) ||
                          '-'}
                    </Typography>
                  </Grid>
                </div>
              ))}
            </div>
          )}
        </Grid>

        {metadataHeaders?.length && metadataData?.length ? (
          <Grid container item xs={12} className="result-table">
            <Grid item xs={12}>
              <DataTable
                title={`${t('testMetadata')}: ${resultName}`}
                onColumnHide={(hiddenColumns) =>
                  setHiddenMetadataColumns(hiddenColumns)
                }
                canHideColumns={editMode}
                canSearchTable={editMode}
                columns={memoizedMetadataColumns}
                data={memoizedMetadataData}
                passedState={
                  !editMode
                    ? {
                        hiddenColumns: [
                          ...(result?.test?.template?.config
                            ?.hiddenMetadataColumns || []),
                        ],
                      }
                    : null
                }
              />
            </Grid>
          </Grid>
        ) : null}

        {!manualInputs?.length &&
        resultHeaders?.length &&
        resultData?.length ? (
          <Grid item xs={12} className="result-table">
            <Grid container item xs={12}>
              {!editMode && (
                <>
                  <Grid
                    container
                    item
                    xs={6}
                    justify="flex-start"
                    alignItems="center"
                  >
                    <Typography variant="h4">
                      {`${t('testResults')}: ${resultName}`}
                    </Typography>
                  </Grid>

                  <Grid
                    container
                    item
                    xs={6}
                    justify="flex-end"
                    alignItems="center"
                  >
                    <Typography variant="body1" display="inline">
                      {t('tableView')}
                    </Typography>
                    <Switch
                      checked={!!viewMode}
                      onChange={(event) => setViewMode(+event.target.checked)}
                      name="viewMode"
                      color="primary"
                    />
                    <Typography variant="body1" display="inline">
                      {t('chartView')}
                    </Typography>
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                {viewMode === TemplateDataView.TABLE ? (
                  <DataTable
                    title={editMode && `${t('testResults')}: ${resultName}`}
                    onColumnHide={(hiddenColumns) =>
                      setHiddenResultColumns(hiddenColumns)
                    }
                    canHideColumns={editMode}
                    canSearchTable={editMode}
                    columns={memoizedResultColumns}
                    data={memoizedResultData}
                    canUsePagination={true}
                    passedState={
                      !editMode
                        ? {
                            hiddenColumns: [
                              ...(result?.test?.template?.config
                                ?.hiddenResultColumns || []),
                            ],
                          }
                        : null
                    }
                  />
                ) : (
                  <DataChart
                    resultRows={resultData}
                    chartConfig={resultTable?.chartConfig}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                <div className="upload-box-container">
                  <UploadFiles
                    uploadedFiles={result.files}
                    uploadCallback={uploadFilesMethod}
                    deleteCallback={deleteFileMethod}
                    preDefinedPath={preDefinedPath}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
        ) : manualInputs?.length ? (
          <Grid item xs={12} className="result-manual-data">
            <TestManualData
              result={result}
              canEditManualInputs={
                !['completed', 'excluded'].includes(project?.status)
              }
            />
          </Grid>
        ) : (
          <Grid item xs={12} className="result-title">
            <Typography variant="h4">{t('noResults')}</Typography>
          </Grid>
        )}

        {!editMode ? (
          <Grid item xs={12} className="result-comments">
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Typography variant="h4">{t('comments')}</Typography>
              </Grid>
              <Grid item xs={12}>
                <ResultCommentModal
                  onInputChangeAction={setComment}
                  clear={clearComment}
                  setClear={setClearComment}
                />
                <Grid
                  container
                  item
                  sm={12}
                  justify="flex-end"
                  className="result-comments-submit"
                >
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={!comment || comment?.trim()?.length === 0}
                    onClick={() => {
                      dispatch(
                        postCommentAction(tableId, resultTableId, { comment }),
                      );
                      setComment('');
                      setClearComment(true);
                    }}
                  >
                    {t('submitComment')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : null}
      </Grid>

      <ProjectDataModal
        isOpen={templateModalOpen}
        closeModal={() => setTemplateModalOpen(false)}
        closeOKModal={submitTemplate}
        title={t('templateModal')}
        submitFormFunction={(data) => saveTemplate(data)}
        disableActions={false}
        maxWidth="sm"
        t={t}
      >
        <SaveTemplate t={t} />
      </ProjectDataModal>
    </>
  );
};

export default FastPreview;
