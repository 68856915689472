import {
  USER_START,
  USER_SUCCESS,
  USER_PROJECT_SUCCESS,
  USER_CLEAR,
} from './UserTypes';
import {
  getUsers,
  getUsersByLocation,
  getUsersForProjects,
  removeUser,
} from 'repository/user';
import {
  ERROR,
  SUCCESS,
  LOADING,
  CLEAR,
} from 'containers/_Default/Notification/NotificationTypes';

export const fetchUsers = (params) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: USER_START,
      });

      dispatch({
        type: LOADING,
        payload: {
          isOpen: true,
          message: 'user_loading',
        },
      });

      const response: any = await getUsers(params);

      setTimeout(() => {
        dispatch({
          type: CLEAR,
        });
      }, 1000);

      dispatch({
        type: USER_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      const message =
        error?.response?.data?.message ||
        error?.response?.data?.detail ||
        error?.response?.data?.title ||
        'error';

      dispatch({
        type: ERROR,
        payload: {
          isOpen: true,
          message,
        },
      });
    }
  };
};

export const fetchUsersByLocation = (params) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: USER_START,
      });

      dispatch({
        type: LOADING,
        payload: {
          isOpen: true,
          message: 'user_loading',
        },
      });

      const response: any = await getUsersByLocation(params);

      setTimeout(() => {
        dispatch({
          type: CLEAR,
        });
      }, 1000);

      dispatch({
        type: USER_SUCCESS,
        payload: { docs: response.data },
      });
    } catch (error) {
      const message =
        error?.response?.data?.message ||
        error?.response?.data?.detail ||
        error?.response?.data?.title ||
        'error';

      dispatch({
        type: ERROR,
        payload: {
          isOpen: true,
          message,
        },
      });
    }
  };
};

export const fetchUsersForProjects = (params) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: USER_START,
      });

      dispatch({
        type: LOADING,
        payload: {
          isOpen: true,
          message: 'user_loading',
        },
      });

      const response: any = await getUsersForProjects();

      setTimeout(() => {
        dispatch({
          type: CLEAR,
        });
      }, 1000);

      dispatch({
        type: USER_PROJECT_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      const message =
        error?.response?.data?.message ||
        error?.response?.data?.detail ||
        error?.response?.data?.title ||
        'error';

      dispatch({
        type: ERROR,
        payload: {
          isOpen: true,
          message,
        },
      });
    }
  };
};

export const deleteUser = (params, cb = () => {}) => {
  return async (dispatch) => {
    try {
      await removeUser(params);

      if (typeof cb === 'function') {
        cb();
      }

      dispatch({
        type: SUCCESS,
        payload: {
          isOpen: true,
          message: 'user_removeSuccess',
        },
      });
    } catch (error) {
      const message =
        error?.response?.data?.message ||
        error?.response?.data?.detail ||
        error?.response?.data?.title ||
        'error';

      dispatch({
        type: ERROR,
        payload: {
          isOpen: true,
          message,
        },
      });
    }
  };
};

export const clearUsers = () => {
  return {
    type: USER_CLEAR,
  };
};
