import {
  RESULT_START,
  RESULT_SUCCESS,
  RESULT_CLEAR,
  RESULT_ADD_TO_EXPORT,
  RESULT_REMOVE_FROM_EXPORT,
  RESULT_CHART_START,
  RESULT_CHART_SUCCESS,
  RESULT_SEARCH_CONFIGURATION_START,
  RESULT_SEARCH_CONFIGURATION_SUCCESS,
  RESULT_SEARCH_CONFIGURATION_ERROR,
} from './TestResultsTypes';
import {
  createSearchConfiguration,
  editManualInputs,
  fetchChartUrl,
  getResultForProject,
  getSearchConfigurations,
} from 'repository/testResult';
import {
  ERROR,
  SUCCESS,
  LOADING,
  CLEAR,
} from 'containers/_Default/Notification/NotificationTypes';
import { DATA_SEARCH_APPEND_STATISTICAL_OPERATION } from '../DataSearch/DataSearchTypes';

export const fetchResultsForProject = (projectId, selectedResults) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: RESULT_START,
      });

      dispatch({
        type: LOADING,
        payload: {
          isOpen: true,
          message: 'results_loadingResults',
        },
      });

      const response: any = await getResultForProject(
        projectId,
        selectedResults,
      );
      setTimeout(() => {
        dispatch({
          type: CLEAR,
        });
      }, 1000);

      dispatch({
        type: RESULT_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      const message =
        error?.response?.data?.message ||
        error?.response?.data?.detail ||
        error?.response?.data?.title ||
        'error';

      dispatch({
        type: ERROR,
        payload: {
          isOpen: true,
          message,
        },
      });
    }
  };
};

export const updateManualInput = (id, data) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: LOADING,
        payload: {
          isOpen: true,
          message: 'results_updatingResult',
        },
      });
      await editManualInputs(id, data);

      dispatch({
        type: SUCCESS,
        payload: {
          isOpen: true,
          message: 'results_resultUpdated',
        },
      });
      setTimeout(() => {
        dispatch({
          type: CLEAR,
        });
      }, 1000);
    } catch (error) {
      const message =
        error?.response?.data?.message ||
        error?.response?.data?.detail ||
        error?.response?.data?.title ||
        'error';

      dispatch({
        type: ERROR,
        payload: {
          isOpen: true,
          message,
        },
      });
    }
  };
};

export const clearResults = () => {
  return {
    type: RESULT_CLEAR,
  };
};

export const addResultToExport = (data) => {
  return async (dispatch) => {
    dispatch({
      type: RESULT_ADD_TO_EXPORT,
      payload: data,
    });
  };
};

export const removeResultFromExport = (data) => {
  return async (dispatch) => {
    dispatch({
      type: RESULT_REMOVE_FROM_EXPORT,
      payload: data,
    });
  };
};

export const appendStatisticalOperations = (data) => {
  return async (dispatch) => {
    dispatch({
      type: DATA_SEARCH_APPEND_STATISTICAL_OPERATION,
      payload: data,
    });
  };
};

export const getChartUrl = (data) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: RESULT_CHART_START,
      });

      dispatch({
        type: LOADING,
        payload: {
          isOpen: true,
          message: 'results_generatingChart',
        },
      });

      const response: any = await fetchChartUrl(data);
      setTimeout(() => {
        dispatch({
          type: CLEAR,
        });
      }, 1000);

      dispatch({
        type: RESULT_CHART_SUCCESS,
        payload: response.data.chartUrl,
      });
    } catch (error) {
      const message =
        error?.response?.data?.message ||
        error?.response?.data?.detail ||
        error?.response?.data?.title ||
        'error';

      dispatch({
        type: ERROR,
        payload: {
          isOpen: true,
          message,
        },
      });
    }
  };
};

export const createSearchConfigurationAction = (data) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: RESULT_SEARCH_CONFIGURATION_START,
      });
      dispatch({
        type: LOADING,
        payload: {
          isOpen: true,
          message: 'results_savingSearchConfig',
        },
      });

      await createSearchConfiguration(data);

      setTimeout(() => {
        dispatch({
          type: CLEAR,
        });
      }, 1000);

      dispatch({
        type: RESULT_SEARCH_CONFIGURATION_SUCCESS,
      });

      await dispatch(fetchSearchConfigurationsAction({}));
    } catch (error) {
      const message =
        error?.response?.data?.message ||
        error?.response?.data?.detail ||
        error?.response?.data?.title ||
        'error';

      dispatch({
        type: ERROR,
        payload: {
          isOpen: true,
          message,
        },
      });
    }
  };
};

export const fetchSearchConfigurationsAction = (data) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: RESULT_SEARCH_CONFIGURATION_START,
      });

      const response: any = await getSearchConfigurations(data);

      if (response.data) {
        dispatch({
          type: RESULT_SEARCH_CONFIGURATION_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: RESULT_SEARCH_CONFIGURATION_ERROR,
          payload: {
            message: 'results_searchConfigsNotFound',
          },
        });
      }
    } catch (error) {
      const message =
        error?.response?.data?.message ||
        error?.response?.data?.detail ||
        error?.response?.data?.title ||
        'error';

      dispatch({
        type: ERROR,
        payload: {
          isOpen: true,
          message,
        },
      });
    }
  };
};
