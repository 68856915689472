import React, { useEffect, useState } from 'react';
import FolderView from '../../components/FolderView/FolderView';
import FolderIcon from '@material-ui/icons/Folder';
import { Grid } from '@material-ui/core';
import ProjectsStepCounter from '../../components/StepCounter/ProjectsStepCounter/ProjectsStepCounter';
import ResultView from './components/ResultView/ResultView';
import { useDispatch, useSelector } from 'react-redux';
import {
  PROJECTDATA_DESELECTRESULT,
  PROJECTDATA_SELECTRESULT,
  PROJECTDATA_SET_ACTIVE_DOCUMENT,
  PROJECTDATA_SET_ACTIVE_RESULT,
} from './ProjectDataTypes';
import ProjectDataNavigation from './components/ProjectDataNavigation/ProjectDataNavigation';
import ProjectDataModal from './components/Modals/ProjectDataModal';
import ResultExcludeModal from './components/Modals/ResultExcludeModal';
import { useForm } from 'react-hook-form';
import ResultCommentModal from './components/Modals/ResultCommentModal';
import {
  completeDocumentAction,
  excludeResultAction,
  fetchProjectOnDataScreen,
  postCommentAction,
  postDocumentCommentAction,
  postDocumentCommentModalAction,
  updateDocumentAction,
} from './ProjectDataActions';
import FastPreview from '../FastPreview/FastPreview';
import { UploadFiles } from '../../components/UploadFiles/UploadFiles';
import {
  deleteFileUploader,
  getUploadFileUploader,
  uploadFiles,
} from '../../repository/testResult';
import { createUploadPathForProject } from '../../utils/upload-helpers';
import { asyncForEach } from '../../utils/util-functions';

import './ProjectData.scss';
import DocumentView from './components/DocumentView/DocumentView';
import DocumentModal from './components/Modals/DocumentModal';
import DocumentForm from './components/DocumentForm/DocumentForm';
import DocumentCommentModal from './components/Modals/DocumentCommentModal';
import { uniqBy } from 'lodash';

enum ModalStates {
  CLOSED,
  COMMENT,
  EXCLUDE,
  CONFIGURE,
  DOCUMENT,
  DOCUMENT_COMMENT,
}

export enum ResultStatus {
  EXCLUDED,
  OK,
}

export const ProjectDataView = ({
  projectData,
  projectId,
  goBackToProjectEdit,
  t,
}) => {
  const dispatch = useDispatch();
  const [modalState, setModalState] = useState(0);
  const closeModal = () => setModalState(ModalStates.CLOSED);
  const closeDocumentsModal = () => {
    setModalState(ModalStates.CLOSED);
    dispatch({
      type: PROJECTDATA_SET_ACTIVE_DOCUMENT,
      payload: {},
    });
  };
  const account = JSON.parse(localStorage.getItem('account') || '{}');
  const {
    company: { _id: companyId },
  } = account;

  const { files, projectId: customProjectId } =
    projectData?.results[0]?.project || [];

  const { tableId, resultTableId, resultName, editMode } =
    projectData.activeResultTable;

  const documentId = projectData?.activeDocument?._id;

  const { documents } = projectData;

  const openCommentModal = (resultId, tableResultId) => {
    dispatch({
      type: PROJECTDATA_SET_ACTIVE_RESULT,
      payload: {
        tableId: resultId,
        resultTableId: tableResultId,
      },
    });
    setModalState(ModalStates.COMMENT);
  };
  const openExcludeModal = (resultId, tableResultId, editMode) => {
    dispatch({
      type: PROJECTDATA_SET_ACTIVE_RESULT,
      payload: {
        tableId: resultId,
        resultTableId: tableResultId,
        editMode,
      },
    });
    setModalState(ModalStates.EXCLUDE);
  };

  const openFastPreview = (projectId, resultId, resultTableId, result) => {
    dispatch({
      type: PROJECTDATA_SET_ACTIVE_RESULT,
      payload: {
        projectId: projectId,
        tableId: resultId,
        resultTableId: resultTableId,
        resultName: result?.name,
        editMode: false,
      },
    });
    setModalState(ModalStates.CONFIGURE);
  };

  const openDocumentModal = (document) => {
    dispatch({
      type: PROJECTDATA_SET_ACTIVE_DOCUMENT,
      payload: {
        ...document,
      },
    });
    setModalState(ModalStates.DOCUMENT);
  };

  const openDocumentCommentModal = (document) => {
    dispatch({
      type: PROJECTDATA_SET_ACTIVE_DOCUMENT,
      payload: {
        ...document,
      },
    });
    setModalState(ModalStates.DOCUMENT_COMMENT);
  };

  const openTemplateConfigurator = (
    projectId,
    resultId,
    resultTableId,
    result,
  ) => {
    dispatch({
      type: PROJECTDATA_SET_ACTIVE_RESULT,
      payload: {
        projectId: projectId,
        tableId: resultId,
        resultTableId: resultTableId,
        resultName: result?.label,
        editMode: true,
      },
    });
    setModalState(ModalStates.CONFIGURE);
  };
  const [treeStructure, setTreeStructure] = useState([] as any);
  const [documentTreeStructure, setDocumentTreeStructure] = useState([] as any);
  const methods = useForm();

  const submitComment = async (data) => {
    methods.handleSubmit(data);
    closeModal();
  };

  const submitDocumentComment = async (data) => {
    dispatch(
      postDocumentCommentAction(projectId, documentId, { comment: data }),
    );
    dispatch({
      type: PROJECTDATA_SET_ACTIVE_DOCUMENT,
      payload: {},
    });
    closeModal();
  };

  const submitDocumentData = async (data) => {
    methods.handleSubmit(data);
    closeModal();
    dispatch({
      type: PROJECTDATA_SET_ACTIVE_DOCUMENT,
      payload: {},
    });
  };

  const completeDocument = async (documentId, data) => {
    dispatch(
      completeDocumentAction(
        projectId,
        documentId,
        data,
        dispatch(fetchProjectOnDataScreen(projectId)),
      ),
    );
    closeModal();
    dispatch({
      type: PROJECTDATA_SET_ACTIVE_DOCUMENT,
      payload: {},
    });
  };

  useEffect(() => {
    const { treeViewStructure } = projectData;
    const structuredTreeView = [] as any;
    for (const [testLevel, subTestLevel] of Object.entries(treeViewStructure)) {
      const processedItem = {
        label: t(`project:testType_${testLevel}`),
        icon: <FolderIcon style={{ fill: '#2c5364' }} />,
        children: [] as { label: string; children: any; icon: any }[],
      };
      for (const [subTestLabel, resultLevel] of Object.entries(
        subTestLevel as any,
      )) {
        const newChildren = [] as any[];
        for (const [indexOfResult, singleResult] of (
          resultLevel as any
        ).entries()) {
          if (
            singleResult.resultTables.length <= 1 ||
            singleResult.device?.manualInputs?.length
          ) {
            singleResult.name =
              singleResult.test?.testName ||
              t(`project:testSubType_${subTestLabel}`) +
                '_' +
                (indexOfResult + 1);
            newChildren.push({
              label: singleResult.name,
              component: (
                <ResultView
                  key={Date.now()}
                  result={{
                    ...singleResult,
                    ...singleResult.resultTables[0],
                    _id: singleResult.resultTables[0]?._id,
                  }}
                  selectResult={() => {
                    dispatch({
                      type: PROJECTDATA_SELECTRESULT,
                      payload: singleResult.resultTables[0]?._id,
                    });
                  }}
                  deselectResult={() => {
                    dispatch({
                      type: PROJECTDATA_DESELECTRESULT,
                      payload: singleResult.resultTables[0]?._id,
                    });
                  }}
                  fastPreview={() => {
                    openFastPreview(
                      projectId,
                      singleResult._id,
                      singleResult.resultTables[0]._id,
                      singleResult,
                    );
                  }}
                  configureTemplate={() => {
                    openTemplateConfigurator(
                      projectId,
                      singleResult._id,
                      singleResult.resultTables[0]._id,
                      singleResult,
                    );
                  }}
                  exclude={() =>
                    openExcludeModal(
                      singleResult._id,
                      singleResult.resultTables[0]?._id,
                      singleResult.resultTables[0].resultStatus ===
                        ResultStatus.EXCLUDED,
                    )
                  }
                  comment={() =>
                    openCommentModal(
                      singleResult._id,
                      singleResult.resultTables[0]?._id,
                    )
                  }
                  hasResults={
                    singleResult.resultTables[0]?.resultTableRows?.length ||
                    singleResult.device?.manualInputs?.length
                  }
                  t={t}
                />
              ),
            });
          } else if (singleResult.resultTables.length > 1) {
            const newTestResult = {
              label: (singleResult.name =
                singleResult.test?.testName || subTestLabel),
              icon: <FolderIcon style={{ fill: '#2c5364' }} />,
              children: [] as {
                label: string;
                children?: any;
                component?: any;
                icon?: any;
              }[],
            };
            singleResult.resultTables.forEach((table, indexOfTable) => {
              singleResult.name = singleResult.test?.testName
                ? singleResult.test?.testName + '_subtest_' + (indexOfTable + 1)
                : t(`project:testSubType_${subTestLabel}`) +
                  '_subtest_' +
                  (indexOfTable + 1);
              newTestResult.children.push({
                label: singleResult.name,
                component: (
                  <ResultView
                    key={Date.now()}
                    result={{ ...singleResult, ...table, _id: table._id }}
                    selectResult={() => {
                      dispatch({
                        type: PROJECTDATA_SELECTRESULT,
                        payload: table._id,
                      });
                    }}
                    deselectResult={() => {
                      dispatch({
                        type: PROJECTDATA_DESELECTRESULT,
                        payload: table._id,
                      });
                    }}
                    fastPreview={() => {
                      openFastPreview(
                        projectId,
                        singleResult._id,
                        singleResult.resultTables[indexOfTable]?._id,
                        {
                          ...singleResult,
                          name: singleResult.test?.testName
                            ? singleResult.test?.testName +
                              '_subtest_' +
                              (indexOfTable + 1)
                            : t(`project:testSubType_${subTestLabel}`) +
                              '_subtest_' +
                              (indexOfTable + 1),
                        },
                      );
                    }}
                    configureTemplate={() => {
                      openTemplateConfigurator(
                        projectId,
                        singleResult._id,
                        singleResult.resultTables[indexOfTable]?._id,
                        singleResult,
                      );
                    }}
                    exclude={() => {
                      openExcludeModal(
                        singleResult._id,
                        table._id,
                        singleResult.resultTables[indexOfTable]
                          ?.resultStatus === ResultStatus.EXCLUDED,
                      );
                    }}
                    comment={() => {
                      openCommentModal(singleResult._id, table._id);
                    }}
                    hasResults={
                      singleResult.resultTables[indexOfTable]?.resultTableRows
                        ?.length
                    }
                    t={t}
                  />
                ),
              });
            });

            newChildren.push(newTestResult);
          }
        }
        processedItem.children.push({
          label: t(`project:testSubType_${subTestLabel}`),
          icon: <FolderIcon style={{ fill: '#2c5364' }} />,
          children: newChildren,
        });
      }
      structuredTreeView.push(processedItem);
    }
    setTreeStructure(structuredTreeView);
  }, [projectData]);

  useEffect(() => {
    const structuredDocumentView: any = [];

    if (documents.length) {
      structuredDocumentView.push({
        label: t('projectDocumentation:documents'),
        icon: <FolderIcon style={{ fill: '#2c5364' }} />,
        children: [] as {
          label: string;
          children: any;
          icon: any;
          component: any;
        }[],
      });

      documents.map((document) => {
        const processedDocument = {
          label: t(document.documentConfig?.name, {
            ns: 'projectDocumentation',
          }),
          icon: <FolderIcon style={{}} />,
          component: (
            <DocumentView
              t={t}
              document={document}
              openModal={() => openDocumentModal(document)}
              comment={() => {
                openDocumentCommentModal(document);
              }}
            />
          ),
          children: [],
        };

        structuredDocumentView[0].children.push(processedDocument);
      });

      setDocumentTreeStructure(structuredDocumentView);
    }
  }, [projectData, projectData.activeDocument._id]);

  const preDefinedPath = createUploadPathForProject(customProjectId, companyId);

  const uploadFilesMethod = async (files) => {
    let formData = new FormData();

    files.forEach((file) => {
      formData.append('files', file);
    });
    try {
      await uploadFiles('/project/' + projectId + '/files', formData);
      return [];
    } catch (e) {
      return files;
    }
  };

  const deleteFileMethod = async (fileName) => {
    return await deleteFileUploader(projectId, fileName);
  };

  return (
    <Grid container className={'project-data-page'}>
      <ProjectsStepCounter />
      <ProjectDataNavigation
        projectData={projectData}
        goBackToProjectEdit={goBackToProjectEdit}
        customProjectId={customProjectId}
        t={t}
      />
      <Grid item xs={12} className={'folder-structure-holder'}>
        <FolderView items={documentTreeStructure} />
      </Grid>
      <Grid item xs={12} className={'folder-structure-holder'}>
        <FolderView items={treeStructure} />
      </Grid>

      <ProjectDataModal
        isOpen={modalState === ModalStates.EXCLUDE}
        closeModal={closeModal}
        closeOKModal={submitComment}
        submitFormFunction={(data: any) => {
          dispatch(
            excludeResultAction(
              tableId,
              resultTableId,
              data.reason,
              data.comment,
              data.commentId,
            ),
          );
          closeModal();
        }}
        title={t('common:comments')}
        disableActions={false}
        maxWidth="sm"
        t={t}
      >
        <ResultExcludeModal />
      </ProjectDataModal>

      <ProjectDataModal
        isOpen={modalState === ModalStates.COMMENT}
        closeModal={closeModal}
        closeOKModal={submitComment}
        title={t('common:comments')}
        submitFormFunction={(data) => {
          dispatch(postCommentAction(tableId, resultTableId, data));
          closeModal();
        }}
        disableActions={false}
        maxWidth="sm"
        t={t}
      >
        <ResultCommentModal />
      </ProjectDataModal>

      <ProjectDataModal
        isOpen={modalState === ModalStates.DOCUMENT_COMMENT}
        closeModal={closeModal}
        closeOKModal={submitComment}
        title={t('common:comments')}
        submitFormFunction={(data) => {
          dispatch(postDocumentCommentAction(projectId, documentId, data));
          closeDocumentsModal();
        }}
        disableActions={false}
        maxWidth="sm"
        t={t}
      >
        <DocumentCommentModal />
      </ProjectDataModal>

      <DocumentModal
        isOpen={modalState === ModalStates.DOCUMENT}
        closeModal={closeDocumentsModal}
        closeOKModal={submitDocumentData}
        completeAction={completeDocument}
        title={t('projectDocumentation:documentDetails')}
        submitFormFunction={(documentId, data) => {
          dispatch(
            updateDocumentAction(
              projectId,
              documentId,
              data,
              dispatch(fetchProjectOnDataScreen(projectId)),
            ),
          );
          closeModal();
          dispatch({
            type: PROJECTDATA_SET_ACTIVE_DOCUMENT,
            payload: {},
          });
        }}
        disableActions={false}
        maxWidth="sm"
        t={t}
        submitComment={(data) => submitDocumentComment(data)}
      >
        <DocumentForm />
      </DocumentModal>

      <ProjectDataModal
        isOpen={modalState === ModalStates.CONFIGURE}
        closeModal={closeModal}
        closeOKModal={submitComment}
        title={!editMode ? `${t('testInformation')}: ${resultName}` : ''}
        submitFormFunction={closeModal}
        disableActions={false}
        maxWidth="xl"
        hideDivider={true}
        hideSubmit
        hideCancel
        t={t}
      >
        <FastPreview
          closeModal={closeModal}
          projectId={projectId}
          tableId={tableId}
          resultTableId={resultTableId}
          resultName={resultName}
          editMode={editMode}
        />
      </ProjectDataModal>
      <Grid item xs={12} className="project-data-page__file-upload">
        <UploadFiles
          uploadedFiles={files}
          uploadCallback={uploadFilesMethod}
          deleteCallback={deleteFileMethod}
          preDefinedPath={preDefinedPath}
        />
      </Grid>
    </Grid>
  );
};
