import React from 'react';
import './MaterialDetails.scss';
import { Button, Grid, Typography } from '@material-ui/core';
import { SpecificationSection } from './components/SpecificationSection';
import { InformationSection } from './components/InformationSection';
import { PageTitle } from '../../components';
import { RecipeSection } from './components/RecipeSection';

export const MaterialDetailsView = (props) => {
  const {
    data,
    addSpecification,
    removeSpecification,
    saveSpecification,
    cancelSpecificationAction,
    saveInformation,
    goBackAction,
    saveRecipeConfig,
    createRecipeConfig,
    allBaseTypeMaterials,
    t,
  } = props;

  return (
    <div className="page-material-details">
      <Grid item container justify={'space-between'}>
        <PageTitle title={data.name}>
          <Button color="primary" onClick={() => goBackAction()}>
            <Typography variant="body2">{t('goBack')}</Typography>
          </Button>
        </PageTitle>
      </Grid>

      <Grid container item spacing={3}>
        {data.type === 'Compound' && (
          <Grid item container xs={6}>
            <RecipeSection
              material={data}
              allBaseTypeMaterials={allBaseTypeMaterials}
              editRecipeConfig={saveRecipeConfig}
              createRecipeConfig={createRecipeConfig}
              t={t}
            />
          </Grid>
        )}
        <Grid item container xs={data.type == 'Compound' ? 6 : 12} spacing={1}>
          <SpecificationSection
            addRow={addSpecification}
            deleteRow={removeSpecification}
            saveSpecification={saveSpecification}
            rows={data.specificationRowMap}
            cancel={cancelSpecificationAction}
            isCompound={data.type === 'Compound'}
            t={t}
          />
          <InformationSection
            material={data}
            saveInformation={saveInformation}
            isCompound={data.type === 'Compound'}
            t={t}
          />
        </Grid>
      </Grid>
    </div>
  );
};
