import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import { Delete as DeleteIcon, Add as AddIcon } from '@material-ui/icons';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import { getFieldFromType } from 'components/Forms/FieldTypes';
import moment from 'moment-timezone';
import { set as setPath, uniqBy as filterUnique } from 'lodash';
import {
  DOCUMENT_TABLE_ADD_ROW,
  DOCUMENT_TABLE_REMOVE_ROW,
} from 'containers/ProjectData/ProjectDataTypes';
import { updateDocumentAction } from 'containers/ProjectData/ProjectDataActions';
import { populateAutoIncrementField } from '../../../../utils/input-helpers';
import { InformationSection } from 'containers/MaterialDetails/components/InformationSection';

export enum FormType {
  TABLE = 'manualTable',
  FORM = 'manualForm',
}

const DocumentForm = () => {
  const {
    documentConfig,
    data,
    completed,
    _id,
    resultsRowMap,
    initialSpecs = [],
    allSpecs = [],
    project,
    material,
  } = useSelector((state) => state.projectData.activeDocument);

  const dispatch = useDispatch();

  const configType = documentConfig?.configType;

  const additionalSpecs = allSpecs.filter(
    (spec) =>
      !initialSpecs.find(
        (tempSpec) =>
          tempSpec.specifications?.config?.name ===
          spec.specifications?.config?.name,
      ),
  );

  const specs =
    [...initialSpecs, ...additionalSpecs]?.map((spec) => {
      const range = spec.specifications?.range
        ? `${spec.specifications?.range.min} - ${spec.specifications?.range.max}`
        : '';

      if (!range) {
        return;
      }

      return {
        children: [],
        header: spec?.specifications?.config?.name,
        fieldType: 'string',
        range: range,
        unit: spec?.specifications?.unit || '',
      };
    }) || [];

  const addTableRowDuplicate = (row, rows) => {
    const previousRow: any = Object.values(rows)
      .map((item) => item)
      .filter((item: any) => !item.deleted)
      .pop();

    specs?.forEach((spec) => {
      if (previousRow) {
        delete previousRow[spec?.header];
      }
    });

    const appConfig = JSON.parse(localStorage.getItem('config') || '{}');
    const config = documentConfig?.config || [];

    const tempRow = {
      ...previousRow,
      ...populateAutoIncrementField(rows, config),
      documentPss_date: moment().tz(appConfig.region).format('DD/MM/yyyy'),
      documentPss_time: moment().tz(appConfig.region).format('HH:mm'),
      generatedId: Date.now(),
    };

    dispatch({
      type: DOCUMENT_TABLE_ADD_ROW,
      payload: tempRow,
    });

    const data = form.getValues();
    const results = Object.values(data)
      .filter((item) => !item.deleted && item)
      .map((item) => {
        delete item.generatedId;
        return item;
      });

    dispatch(updateDocumentAction(project, _id, { results: results }));
  };
  const removeTableRow = (row) => {
    dispatch({
      type: DOCUMENT_TABLE_REMOVE_ROW,
      payload: row,
    });
  };

  const prefillRow = (row) => {
    dispatch({
      type: DOCUMENT_TABLE_ADD_ROW,
      payload: {
        ...row,
        generatedId: 'generatedRow',
      },
    });
  };

  useEffect(() => {}, [_id]);

  const form = useFormContext();

  const { t } = useTranslation('projectDocumentation');

  const { t: materialT } = useTranslation('materials');

  const components = [] as any;

  if (configType === FormType.FORM) {
    documentConfig?.config.map((config, index) => {
      if (config.fieldType === 'section') {
        components.push(
          <DocumentFormSection
            data={data}
            form={form}
            config={config}
            t={t}
            editMode={!completed}
            key={index + documentConfig?._id}
          />,
          <Divider style={{ width: '100%' }} />,
        );
      } else if (
        ['string', 'number', 'checkbox'].includes(
          config.fieldType.toLowerCase(),
        ) &&
        documentConfig?.editOnProjectPage
      ) {
        components.push(
          <DocumentFormInputs
            data={data}
            form={form}
            config={config}
            t={t}
            index={index + documentConfig?._id}
            editMode={false}
            key={index + documentConfig?._id}
          />,
        );
      } else if (
        ['string', 'number', 'checkbox'].includes(
          config.fieldType.toLowerCase(),
        ) &&
        !documentConfig?.editOnProjectPage
      ) {
        components.push(
          <DocumentFormInputs
            data={data}
            form={form}
            config={config}
            t={t}
            index={index + documentConfig?._id}
            editMode={!completed}
            key={index + documentConfig?._id}
          />,
        );
      }
    });
  } else {
    components.push(
      <DocumentTable
        form={form}
        documentConfig={documentConfig}
        rows={resultsRowMap}
        initialSpecs={specs}
        prefillRow={prefillRow}
        addRowDuplicate={addTableRowDuplicate}
        deleteRow={removeTableRow}
        t={t}
      />,
    );
  }

  if (documentConfig?.editOnProjectPage && material) {
    components.push(
      <Grid xs={12} container justify="flex-end">
        <InformationSection
          material={material}
          isCompound={material?.type === 'Compound'}
          saveInformation={() => {}}
          t={materialT}
          canEdit={false}
        />
      </Grid>,
    );
  }

  return (
    <Grid container xs={12} spacing={3}>
      {components.map((component) => component)}
    </Grid>
  );
};

const DocumentFormSection = ({ form, config, data, t, editMode }) => {
  const { fieldType, children, header } = config;

  const [open, setOpen] = useState(true);

  return (
    <Grid item xs={12}>
      <Accordion expanded={open} onChange={() => setOpen(!open)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h4">{t(header)}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            {children.map((child, index) => {
              const fieldData: any = {
                fieldName: child.header,
                label: child.header,
                type: child.fieldType.toLowerCase(),
                required: false,
                className: 'login-input',
                span: 12,
                disabled: !editMode,
              };

              return (
                <>
                  {getFieldFromType(
                    form,
                    fieldData,
                    index + 'section',
                    data,
                    t,
                    editMode,
                  )}{' '}
                  <Typography>{child.measurementUnit}</Typography>
                </>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

const DocumentFormInputs = ({ form, config, data, t, index, editMode }) => {
  const { fieldType, header, measurementUnit } = config;
  const fieldData: any = {
    fieldName: header,
    label: `${t(header)} ${measurementUnit ? `(${t(measurementUnit)})` : ''}`,
    type: fieldType.toLowerCase() === 'string' ? 'text' : fieldType,
    required: false,
    className: 'login-input',
    span: 12,
    disabled: !editMode,
  };

  return (
    <Grid item xs={6} style={{ minHeight: '100px' }} key={index}>
      <Grid
        container
        justify="flex-start"
        alignContent="center"
        alignItems="center"
      >
        {getFieldFromType(form, fieldData, index, data, t, editMode)}
      </Grid>
    </Grid>
  );
};

const DocumentTable = ({
  form,
  documentConfig,
  initialSpecs,
  rows,
  prefillRow,
  addRowDuplicate,
  deleteRow,
  t,
}) => {
  const [rowState, setRowState] = useState(rows);
  useEffect(() => {
    setRowState(Object.values(rows));
  }, [rows]);

  const onUpdate = (fieldName, value) => {
    setPath(rows, fieldName, value);
  };

  const { documents } = useSelector((state) => state.projectData);

  const config = documentConfig?.config || [];

  const configWithSpecs = [...config, ...initialSpecs].filter(
    (config) => config,
  );

  console.log(configWithSpecs);

  const { filterArguments, fields } = documentConfig?.prefilledBy[0] || {};

  const appConfig = JSON.parse(localStorage.getItem('config') || '{}');

  if (filterArguments && fields) {
    const foundDocument = documents?.find(
      ({ documentConfig }) =>
        documentConfig?.name === filterArguments?.typeName,
    );

    if (foundDocument) {
      const prefillData = foundDocument?.data;

      const prefilledRowData = {};

      if (prefillData) {
        Object.keys(fields)?.forEach((key) => {
          prefilledRowData[key] = prefillData[key];
        });
      }

      if (initialSpecs.length) {
        for (const spec of initialSpecs as any) {
          if (spec?.range) {
            prefilledRowData[spec.header] = spec?.range;
          }
        }
      }

      const tempPrefilledRowData = {
        ...prefilledRowData,
        ...populateAutoIncrementField(rows, config),
        documentPss_date: moment().tz(appConfig.region).format('DD/MM/yyyy'),
        documentPss_time: moment().tz(appConfig.region).format('HH:mm'),
      };
      if (Object.values(rows).length === 0) {
        prefillRow(tempPrefilledRowData);
      }
    }
  }

  return (
    <Grid item xs={12}>
      <Grid container xs={12}>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                {configWithSpecs?.map((field) => (
                  <TableCell style={{ minWidth: 100 }}>
                    {t(field.header)}
                    {field.unit ? (
                      <>
                        <Divider
                          orientation="horizontal"
                          style={{ marginBottom: 10 }}
                        />{' '}
                        {field.unit}
                      </>
                    ) : null}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.values(rowState).map((row: any, index) => {
                return (
                  !row.deleted && (
                    <TableRow key={'table-' + row.generatedId}>
                      {configWithSpecs?.map((field) => {
                        const fieldData: any = {
                          fieldName: `${row.generatedId}.${field.header}`,
                          label: '',
                          type: 'table-text',
                          required: false,
                          className: 'login-input',
                          span: 12,
                          disabled: false,
                        };
                        return (
                          <TableCell style={{ minWidth: 100 }}>
                            {getFieldFromType(
                              form,
                              fieldData,
                              row.generatedId + index,
                              rows,
                              t,
                              !field.disabled,
                              false,
                              onUpdate,
                            )}
                          </TableCell>
                        );
                      })}
                      <TableCell>
                        <Controller
                          name={`${row.generatedId}.generatedId`}
                          defaultValue={row.generatedId}
                          render={() => (
                            <TextField
                              name={`${row.generatedId}.generatedId`}
                              value={row.generatedId}
                              type={'hidden'}
                            />
                          )}
                          control={form.control}
                        />
                        <Controller
                          name={`${row.generatedId}.deleted`}
                          defaultValue={row.deleted}
                          render={() => (
                            <TextField
                              name={`${row.generatedId}.deleted`}
                              type={'hidden'}
                              value={row.deleted}
                            />
                          )}
                          control={form.control}
                        />
                      </TableCell>
                    </TableRow>
                  )
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Grid container justify={'flex-end'} key={'edit-' + true}>
          <IconButton
            // key={index}
            aria-label="edit"
            color="secondary"
            size="small"
            disableFocusRipple
            disableRipple
            disableTouchRipple
            onClick={() => addRowDuplicate({ generatedId: Date.now() }, rows)}
          >
            <AddIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DocumentForm;
