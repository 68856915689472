import { addTemplate } from '../../repository/project';
import {
  getSingleResultForProject,
  getSingleResultTable,
} from '../../repository/testResult';
import { fetchProjectOnDataScreen } from '../ProjectData/ProjectDataActions';
import {
  CLEAR,
  ERROR,
  LOADING,
} from '../_Default/Notification/NotificationTypes';
import {
  FASTPREVIEW_GET_RESULT,
  FASTPREVIEW_GET_RESULT_TABLE,
  FASTPREVIEW_START,
  FASTPREVIEW_CLEAR,
} from './FastPreviewTypes';

export const fetchResultForProject = (params) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: FASTPREVIEW_START,
      });

      dispatch({
        type: LOADING,
        payload: {
          isOpen: true,
          message: 'loadingFastPreview',
        },
      });

      const response: any = await getSingleResultForProject(params);

      dispatch({
        type: FASTPREVIEW_GET_RESULT,
        payload: response.data,
      });

      setTimeout(() => {
        dispatch({
          type: CLEAR,
        });
      }, 1000);
    } catch (error) {
      const message =
        error?.response?.data?.message ||
        error?.response?.data?.detail ||
        error?.response?.data?.title ||
        'error';

      dispatch({
        type: ERROR,
        payload: {
          isOpen: true,
          message,
        },
      });
    }
  };
};

export const fetchResultTable = (params) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: FASTPREVIEW_START,
      });

      dispatch({
        type: LOADING,
        payload: {
          isOpen: true,
          message: 'loadingResultsDetails',
        },
      });

      const response: any = await getSingleResultTable(params);

      dispatch({
        type: FASTPREVIEW_GET_RESULT_TABLE,
        payload: response.data,
      });

      setTimeout(() => {
        dispatch({
          type: CLEAR,
        });
      }, 1000);
    } catch (error) {
      const message =
        error?.response?.data?.message ||
        error?.response?.data?.detail ||
        error?.response?.data?.title ||
        'error';

      dispatch({
        type: ERROR,
        payload: {
          isOpen: true,
          message,
        },
      });
    }
  };
};

export const clearPreviewData = () => {
  return {
    type: FASTPREVIEW_CLEAR,
  };
};

export const saveTemplateAction = (params) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: FASTPREVIEW_START,
      });

      dispatch({
        type: LOADING,
        payload: {
          isOpen: true,
          message: 'templates_saving',
        },
      });

      await addTemplate(params);

      dispatch({
        type: FASTPREVIEW_CLEAR,
      });

      dispatch(fetchProjectOnDataScreen(params.id));

      setTimeout(() => {
        dispatch({
          type: CLEAR,
        });
      }, 1000);
    } catch (error) {
      const message =
        error?.response?.data?.message ||
        error?.response?.data?.detail ||
        error?.response?.data?.title ||
        'error';

      dispatch({
        type: ERROR,
        payload: {
          isOpen: true,
          message,
        },
      });
    }
  };
};
