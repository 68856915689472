import {
  PROJECTDATA_START,
  PROJECTDATA_GET_RESULTS,
  PROJECTDATA_ERROR,
  PROJECTDATA_PROCESS_RESULTS,
  PROJECTDATA_UPDATE_RESULTTABLE,
  PROJECTDATA_CLEAR_SELECTED,
  PROJECT_DATA_COMMENTS,
  PROJECTDATA_GET_DOCUMENTS,
  PROJECTDATA_COMPLETE_DOCUMENT,
  PROJECTDATA_UPDATE_DOCUMENT,
  PROJECT_DATA_RESET_STATE,
  PROJECT_DATA_DOCUMENT_COMMENTS,
  PROJECTDATA_SET_ACTIVE_DOCUMENT,
} from './ProjectDataTypes';
import {
  ERROR,
  LOADING,
  CLEAR,
  SUCCESS,
} from 'containers/_Default/Notification/NotificationTypes';
import {
  getResultForProject,
  excludeResult,
  postComment,
  postDocumentComment,
} from '../../repository/testResult';
import {
  completeDocument,
  getProject,
  getProjectDocuments,
  updateDocument,
} from 'repository/project';
import { getMaterial, getMaterialSpecifications } from 'repository/material';

export const fetchProjectOnDataScreen = (params) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: PROJECTDATA_CLEAR_SELECTED,
      });

      dispatch({
        type: PROJECTDATA_START,
      });

      dispatch({
        type: LOADING,
        payload: {
          isOpen: true,
          message: 'loadingProjectData',
        },
      });

      const response: any = await getResultForProject(params, null);

      const project = await getProject(params);

      const documents: any = await getProjectDocuments(params);

      let materialSpecifications: any = [];

      let projectMaterial: any = [];

      if (project.data.dbMaterial) {
        materialSpecifications = await getMaterialSpecifications(
          project.data.dbMaterial,
        );
        projectMaterial = await getMaterial(project.data.dbMaterial);
      }

      documents?.data?.forEach((document) => {
        document.allSpecs = materialSpecifications?.data;
        document.material = projectMaterial?.data;
      });

      dispatch({
        type: PROJECTDATA_GET_DOCUMENTS,
        payload: documents.data,
      });

      dispatch({
        type: PROJECTDATA_GET_RESULTS,
        payload: response.data,
      });
      dispatch({
        type: PROJECTDATA_PROCESS_RESULTS,
      });

      setTimeout(() => {
        dispatch({
          type: CLEAR,
        });
      }, 1000);
    } catch (error) {
      const message =
        error?.response?.data?.message ||
        error?.response?.data?.detail ||
        error?.response?.data?.title ||
        'error';

      dispatch({
        type: ERROR,
        payload: {
          isOpen: true,
          message,
        },
      });
    }
  };
};

export const excludeResultAction = (
  resultID,
  resultTableID,
  reason,
  comment,
  commentId = '',
) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: LOADING,
        payload: {
          isOpen: true,
          message: 'excludingResult',
        },
      });
      const response = await excludeResult(
        resultID,
        resultTableID,
        reason,
        comment,
        commentId,
      );
      dispatch({
        type: PROJECTDATA_UPDATE_RESULTTABLE,
        payload: {
          resultID,
          resultTable: response.data,
        },
      });
      dispatch({
        type: PROJECTDATA_PROCESS_RESULTS,
      });
      dispatch({
        type: SUCCESS,
        payload: {
          isOpen: true,
          message: 'resultMarkedNotOk',
        },
      });

      setTimeout(() => {
        dispatch({
          type: CLEAR,
        });
      }, 1000);
    } catch (error) {
      const message =
        error?.response?.data?.message ||
        error?.response?.data?.detail ||
        error?.response?.data?.title ||
        'error';

      dispatch({
        type: ERROR,
        payload: {
          isOpen: true,
          message,
        },
      });
    }
  };
};

export const completeDocumentAction = (
  projectId,
  documentId,
  data,
  callback: any = () => {},
) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: LOADING,
        payload: {
          isOpen: true,
          message: 'completingDocument',
        },
      });
      const response = await completeDocument(projectId, documentId, data);
      dispatch({ type: PROJECTDATA_COMPLETE_DOCUMENT, payload: response.data });
      const documents: any = await getProjectDocuments(projectId);

      dispatch({
        type: PROJECTDATA_GET_DOCUMENTS,
        payload: documents.data,
      });
      dispatch({
        type: SUCCESS,
        payload: {
          isOpen: true,
          message: 'documentCompleted',
        },
      });

      setTimeout(() => {
        dispatch({
          type: CLEAR,
        });
      }, 1000);

      if (typeof callback === 'function') {
        callback();
      }
    } catch (error) {
      const message =
        error?.response?.data?.message ||
        error?.response?.data?.detail ||
        error?.response?.data?.title ||
        'error';

      dispatch({
        type: ERROR,
        payload: {
          isOpen: true,
          message,
        },
      });
    }
  };
};

export const updateDocumentAction = (
  projectId,
  documentId,
  data,
  callback: any = () => {},
) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: LOADING,
        payload: {
          isOpen: true,
          message: 'updatingDocument',
        },
      });
      const response = await updateDocument(projectId, documentId, data);
      dispatch({ type: PROJECTDATA_UPDATE_DOCUMENT, payload: response.data });
      const documents: any = await getProjectDocuments(projectId);

      dispatch({
        type: PROJECTDATA_GET_DOCUMENTS,
        payload: documents.data,
      });
      dispatch({
        type: SUCCESS,
        payload: {
          isOpen: true,
          message: 'documentUpdated',
        },
      });

      fetchProjectOnDataScreen(projectId);

      setTimeout(() => {
        dispatch({
          type: CLEAR,
        });
      }, 1000);

      if (typeof callback === 'function') {
        callback();
      }
    } catch (error) {
      const message =
        error?.response?.data?.message ||
        error?.response?.data?.detail ||
        error?.response?.data?.title ||
        'error';

      dispatch({
        type: ERROR,
        payload: {
          isOpen: true,
          message,
        },
      });
    }
  };
};

export const postCommentAction = (resultID, resultTableID, body) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: LOADING,
        payload: {
          isOpen: true,
          message: 'commenting',
        },
      });
      const response = await postComment(resultID, resultTableID, body);
      dispatch({
        type: PROJECTDATA_UPDATE_RESULTTABLE,
        payload: {
          resultID,
          resultTable: response.data,
        },
      });
      dispatch({
        type: PROJECTDATA_PROCESS_RESULTS,
      });

      dispatch({
        type: PROJECT_DATA_COMMENTS,
        payload: {
          comments: response.data.comments,
        },
      });

      dispatch({
        type: SUCCESS,
        payload: {
          isOpen: true,
          message: 'commentSaved',
        },
      });

      setTimeout(() => {
        dispatch({
          type: CLEAR,
        });
      }, 1000);
    } catch (error) {
      const message =
        error?.response?.data?.message ||
        error?.response?.data?.detail ||
        error?.response?.data?.title ||
        'error';

      dispatch({
        type: ERROR,
        payload: {
          isOpen: true,
          message,
        },
      });
    }
  };
};

export const postDocumentCommentAction = (projectId, documentId, body) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: LOADING,
        payload: {
          isOpen: true,
          message: 'commenting',
        },
      });
      await postDocumentComment(projectId, documentId, body);
      const documents: any = await getProjectDocuments(projectId);

      dispatch({
        type: PROJECTDATA_GET_DOCUMENTS,
        payload: documents.data,
      });

      dispatch({
        type: SUCCESS,
        payload: {
          isOpen: true,
          message: 'commentSaved',
        },
      });

      setTimeout(() => {
        dispatch({
          type: CLEAR,
        });
      }, 1000);
    } catch (error) {
      const message =
        error?.response?.data?.message ||
        error?.response?.data?.detail ||
        error?.response?.data?.title ||
        'error';

      dispatch({
        type: ERROR,
        payload: {
          isOpen: true,
          message,
        },
      });
    }
  };
};

export const postDocumentCommentModalAction = (projectId, documentId, body) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: LOADING,
        payload: {
          isOpen: true,
          message: 'commenting',
        },
      });
      await postDocumentComment(projectId, documentId, body);
      const documents: any = await getProjectDocuments(projectId);

      dispatch({
        type: PROJECTDATA_GET_DOCUMENTS,
        payload: documents.data,
      });

      dispatch({
        type: SUCCESS,
        payload: {
          isOpen: true,
          message: 'commentSaved',
        },
      });

      setTimeout(() => {
        dispatch({
          type: CLEAR,
        });
      }, 1000);
    } catch (error) {
      const message =
        error?.response?.data?.message ||
        error?.response?.data?.detail ||
        error?.response?.data?.title ||
        'error';

      dispatch({
        type: ERROR,
        payload: {
          isOpen: true,
          message,
        },
      });
    }
  };
};
