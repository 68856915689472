import React from 'react';
import { Grid } from '@material-ui/core';
import { UploadFiles } from '../../../../components/UploadFiles/UploadFiles';
import { deleteFile, uploadFiles } from '../../../../repository/testResult';
import { ManualInputs } from '../ManualInputs/ManualInputs';
import { createUploadPathForResult } from 'utils/upload-helpers';

const TestManualData = ({
  result,
  canEditManualInputs = false,
  hideManualInputs = false,
}) => {
  const preDefinedPath = createUploadPathForResult(result);
  const uploadFilesMethod = async (files) => {
    let formData = new FormData();

    files.forEach((file) => {
      formData.append('files', file);
    });
    try {
      await uploadFiles('/result/' + result._id + '/files', formData);
      return [];
    } catch (e) {
      return files;
    }
  };

  const concatManualInputs = (resultInputs, deviceInputs) => {
    const outputObject = {};

    deviceInputs?.forEach((input) => {
      outputObject[input.label] = { value: input.value, name: input.name };
    });
    resultInputs?.forEach((input) => {
      outputObject[input.label] = { value: input.value, name: input.name };
    });

    return Object.keys(outputObject).map((key) => ({
      label: key,
      value: outputObject[key].value,
      name: outputObject[key].name,
    }));
  };

  const deleteFileMethod = async (fileName) => {
    return await deleteFile(result._id, fileName);
  };
  return (
    <Grid container justify={'space-between'} spacing={2}>
      {!hideManualInputs ? (
        <Grid item container xs={6}>
          {result.device?.manualInputs?.length ? (
            <ManualInputs
              containerClasses="box-holder upload-box"
              fields={concatManualInputs(
                result.resultTables[0]?.manualInputs,
                result.device?.manualInputs,
              )}
              resultId={result._id}
              deviceName={result.device?.deviceName}
              canEdit={canEditManualInputs}
            />
          ) : null}
        </Grid>
      ) : null}
      <Grid item container xs={hideManualInputs ? 12 : 6}>
        <UploadFiles
          uploadedFiles={result.files}
          uploadCallback={uploadFilesMethod}
          deleteCallback={deleteFileMethod}
          preDefinedPath={preDefinedPath}
        />
      </Grid>
    </Grid>
  );
};

export default TestManualData;
