import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Grid, InputLabel, TextField } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import Comment from 'components/Comments/Comments';
import MentionPerson from '../../../../components/MentionPerson/MentionPerson';
import { fetchUsers } from '../../../User/UserActions';
import { useTranslation } from 'react-i18next';

const DocumentCommentModal = ({
  clear = false,
  documentComment = false,
  documentComments = [],
  setClear = (_) => {},
  onInputChangeAction = (_) => {},
}) => {
  const [value, setInputValue] = useState('');
  const dispatch = useDispatch();
  const users = useSelector((state: any) => state.user.data.docs);

  const { t } = useTranslation('projectData');

  useEffect(() => {
    dispatch(fetchUsers({ limit: Number.MAX_SAFE_INTEGER, page: 0 }));
  }, [dispatch]);

  const {
    control,
    register,
    setValue,
    formState,
    clearErrors,
  } = useFormContext();

  const document = useSelector(
    (state: any) => state.projectData.activeDocument,
  );

  const [comments, setComments] = useState(document?.comments || []);

  useEffect(() => {
    return () => {
      setInputValue('');
      setValue('comment', '');
      clearErrors();
    };
  }, []);

  useEffect(() => {
    // (optional) Trigger clear from parent component
    if (clear) {
      setInputValue('');
      setValue('comment', '');
      clearErrors();
      setClear(false);
    }
  }, [clear]);

  useEffect(() => {
    setComments(document?.comments || []);
  }, [document]);

  const formatInput = () => {
    setInputValue(value.trim());
    setValue('comment', value.trim());
  };

  return (
    <Grid item container xs={12}>
      <Grid item container xs={12} className="result-comment-modal-comments">
        {comments.map((comment) => (
          <Comment
            _id={comment._id}
            datePosted={comment.createdAt}
            createdBy={comment.createdBy?.email}
            comment={comment.comment?.trim()}
            type={comment.type}
          />
        ))}
      </Grid>
      <Grid item container direction={'column'} style={{ marginTop: '30px' }}>
        <InputLabel htmlFor={'document-comment'}>
          {t('project:comment')}
        </InputLabel>
        <Controller
          name="comment"
          rules={{ required: true }}
          control={control}
          defaultValue={value}
          render={({ field: { onChange, onBlur, value } }) => {
            const { ref, ...rest } = register('comment', {
              required: true,
            });
            return (
              <>
                <MentionPerson
                  value={value}
                  users={users}
                  onBlur={formatInput}
                  onChange={(event) => {
                    setInputValue(event.target.value);
                    setValue('comment', event.target.value);
                    onChange(event.target.value);
                    onInputChangeAction(event.target.value);
                  }}
                />
              </>
            );
          }}
        />
      </Grid>
    </Grid>
  );
};

export default DocumentCommentModal;
