import API from 'services/api';

export const createUser = async (data: any) => {
  return await API.post('/user', data);
};

export const createUserLabAdmin = async (data: any) => {
  return await API.post('/user/lab-admin', data);
};

export const createUserAdmin = async (data: any) => {
  return await API.post('/user/admin', data);
};

export const initUser = async (id: string, data: any) => {
  return await API.post(`/user/init/${id}`, data);
};

export const editUser = async (id: number, data: any) => {
  const bodyData = { ...data };
  // Delete extra fields from state
  delete bodyData.data;
  delete bodyData.name;
  delete bodyData.loading;
  delete bodyData.error;
  // Delete fields that can't be changed
  delete bodyData.company;

  return await API.put(`/user/${id}`, bodyData);
};

export const getUser = async (id) => {
  return await API.get(`/user/${id}`);
};

export const getAllUser = async (params = {}) => {
  return await API.get(`/user/all`, { params });
};

export const removeUser = async (ids) => {
  return await API.delete(`/user`, {
    data: {
      ids,
    },
  });
};

export const getUsers = async (props) => {
  const { limit = 10, page = 0, sort, name, filter = '' } = props;
  return await API.get('/user', {
    params: { limit, page, sort, name, filter },
  });
};

export const getUsersByLocation = async (props) => {
  return await API.get(`/user/location/${props.location}`);
};

export const getUsersForProjects = async () => {
  return await API.get('/user/project-members');
};
