import React, { useEffect, useState } from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { getFieldFromType } from '../../../components/Forms/FieldTypes';
import { useForm } from 'react-hook-form';

export const InformationSection = ({
  material,
  saveInformation,
  isCompound,
  t,
  canEdit = true,
}) => {
  const [editMode, setEditMode] = useState(false);
  const form = useForm({});
  const [materialState, setMaterialState] = useState({ ...material });
  form.setValue('type', material.type);

  const config = JSON.parse(localStorage.getItem('config') || '{}');
  const fields = Object.values(config?.forms?.materialForm?.tab1?.fields)?.map(
    (field: any) => ({
      ...field,
      type: ['text', 'number'].includes(field.type)
        ? 'project-text'
        : ['search-select', 'select'].includes(field.type)
        ? 'project-search-select'
        : field.type,
    }),
  );

  useEffect(() => {
    setMaterialState({ ...material });
  }, [material]);
  return (
    <Grid
      className={'box-holder'}
      xs={isCompound ? 12 : 5}
      style={{ height: 'min-content' }}
    >
      <form
        onSubmit={form.handleSubmit(async (data) => {
          const isFormValid = await form.trigger();
          if (isFormValid) {
            const success = await saveInformation(data);
            if (success) {
              setEditMode(false);
            }
          }
        })}
      >
        <Grid container justify={'space-between'} alignItems={'center'}>
          <Grid item xs={6}>
            <Typography variant={'h3'}>{t('information')}</Typography>
          </Grid>
          <Grid item container xs={4}>
            {editMode && canEdit && (
              <>
                <Grid item xs={6}>
                  <Button
                    color="default"
                    variant="contained"
                    onClick={() => {
                      form.reset();
                      setEditMode(false);
                    }}
                  >
                    {t('common:cancel')}
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button color="default" variant="outlined" type={'submit'}>
                    {t('save')}
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
          {!editMode && canEdit && (
            <Button
              color="default"
              variant="outlined"
              onClick={() => setEditMode(true)}
            >
              {t('edit')}
            </Button>
          )}
        </Grid>
        <Grid container>
          {fields.map((field: any, index) => {
            field.label = t(field.label);
            field.error = t(field.error);
            if (field.options) {
              field.options.forEach((option) => {
                option.label = t(option.label);
              });
            }
            return getFieldFromType(
              form,
              field,
              index,
              materialState,
              t,
              field.fieldName !== 'type' ? editMode : false,
            );
          })}
        </Grid>
      </form>
    </Grid>
  );
};
