import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { MaterialRecipeComparisonView } from './MaterialRecipeComparisonView';
import { fetchRecipesByMaterial } from './MaterialRecipeComparisonActions';
import { MATERIAL_RECIPE_COMPARISON_RESET } from './MaterialRecipeComparisonTypes';
import { useTranslation } from 'react-i18next';

export default (props) => {
  const { t } = useTranslation(['materials', 'common']);
  const dispatch = useDispatch();
  const materialRecipes = useSelector((state: any) => state.materialRecipes);
  const { id } = props.match.params;

  const [filters, setFilters] = useState();

  const getAllRecipesByMaterial = () => {
    dispatch(fetchRecipesByMaterial(id));
  };

  const goBackAction = () => {
    props.history.push(`/dashboard/material/${id}`);
  };

  useEffect(() => {
    return () => {
      dispatch({
        type: MATERIAL_RECIPE_COMPARISON_RESET,
      });
    };
  }, []);

  return (
    <MaterialRecipeComparisonView
      materialRecipesData={materialRecipes.data}
      isInModal={!props.history}
      onSelect={props.onSelect}
      selectedData={props.selectedData}
      materialId={id}
      filters={filters}
      setFilters={setFilters}
      goBackAction={goBackAction}
      getAllRecipesByMaterial={getAllRecipesByMaterial}
      t={t}
    />
  );
};
