export const PROJECTDATA_START = 'projectdata/projectdata-start';
export const PROJECTDATA_SUCCESS = 'projectdata/projectdata-success';
export const PROJECTDATA_SAVE_SUCCESS = 'projectdata/projectdata-save-success';
export const PROJECTDATA_GET_RESULTS = 'projectdata/projectdata-get-results';
export const PROJECTDATA_GET_DOCUMENTS =
  'projectdata/projectdata-get-documents';
export const PROJECTDATA_SET_ACTIVE_DOCUMENT =
  'projectdata/projectdata-set-active-document';
export const PROJECTDATA_COMPLETE_DOCUMENT =
  'projectdata/projectdata-complete-document';
export const PROJECTDATA_UPDATE_DOCUMENT =
  'projectdata/projectdata-update-document';
export const PROJECTDATA_ERROR = 'projectdata/projectdata-error';
export const PROJECTDATA_SELECTALLRESULTS =
  'projectdata/projectdata-select-all-results';
export const PROJECTDATA_SELECTRESULT = 'projectdata/projectdata-select-result';
export const PROJECTDATA_DESELECTALLRESULTS =
  'projectdata/projectdata-deselect-all-results';
export const PROJECTDATA_DESELECTRESULT =
  'projectdata/projectdata-deselect-result';
export const PROJECTDATA_PROCESS_RESULTS =
  'projectdata/projectdata-process-results';
export const PROJECTDATA_UPDATE_RESULTTABLE =
  'projectdata/projectdata-update-resulttable';
export const PROJECTDATA_SET_ACTIVE_RESULT =
  'projectdata/projectdata-set-active-result';
export const PROJECTDATA_CLEAR_SELECTED =
  'projectdata/projectdata-clear-selected';
export const PROJECT_DATA_RESET_STATE = 'projectdata/projectdata-reset-state';
export const PROJECT_DATA_COMMENTS = 'projectdata/projectdata-comments';
export const PROJECT_DATA_DOCUMENT_COMMENTS =
  'projectdata/projectdata-document-comments';
export const DOCUMENT_TABLE_ADD_ROW =
  'projectdata/projectdata-document-table-add-row';
export const DOCUMENT_TABLE_REMOVE_ROW =
  'projectdata/projectdata-document-table-remove-row';
