import {
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import {
  transformDataForChart,
  transformDataForChartWithConfig,
  transformHeadersForChartTable,
  transformHeadersForManualInputsTable,
  transformHeadersForResultsTable,
} from 'utils/transform-helpers';

import themeConfig from './themeConfig';

import './DataChart.scss';

const renderLegend = (props) => {
  const { payload, onClick } = props;

  return (
    <Grid container justify="center">
      {payload.map((entry, index) => {
        return (
          <Grid item xs={3} key={`item-${index}`}>
            <FormControlLabel
              control={<Checkbox defaultChecked />}
              label={entry.value}
              onChange={(event: any) =>
                onClick(entry.value, event.target.checked)
              }
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export const DataChart = ({
  resultRows,
  hiddenRows = [],
  chartConfig = null as any,
}) => {
  const [parsedData, setParsedData] = useState([]);
  const [parsedKeys, setParsedKeys] = useState([]);

  const [yMax, setYMax] = useState(null as any);

  const [hiddenParameters, setHiddenParameters] = useState(hiddenRows as any);

  const onLegendItemClick = (item, checked) => {
    if (!checked) {
      setHiddenParameters([...hiddenParameters, item]);
    } else {
      setHiddenParameters([
        ...hiddenParameters.filter((param) => param !== item),
      ]);
    }
  };

  const transformHeadersForManualInputsTable = useMemo(
    () => transformHeadersForChartTable(parsedKeys, hiddenRows),
    [parsedKeys],
  );

  useEffect(() => {
    if (resultRows?.length) {
      const { data, keys, yMax = null } = chartConfig
        ? (transformDataForChartWithConfig(resultRows, chartConfig) as any)
        : (transformDataForChart(resultRows) as any);
      setParsedData(data as any);
      setParsedKeys(keys as any);
      setYMax(yMax as any);
    }
  }, [resultRows]);

  return (
    <Grid container className="chart-container">
      <Grid item sm={12}>
        <ResponsiveContainer width="100%" height={400}>
          <LineChart
            width={500}
            height={300}
            data={parsedData}
            margin={{
              top: 5,
              right: 0,
              left: 70,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey={chartConfig?.x || 'name'}
              domain={['dataMin', 'dataMax']}
            />
            <YAxis
              dataKey={chartConfig?.y || false}
              domain={['dataMin', yMax || 'dataMax']}
              type="number"
            />
            <Tooltip />
            <Legend
              onClick={onLegendItemClick}
              content={renderLegend}
              payload={transformHeadersForManualInputsTable}
            />
            {parsedKeys?.map((key, index) => {
              if (key !== 'name' && !hiddenParameters.includes(key)) {
                return (
                  <Line
                    key={`chart-${key}-${index}`}
                    type="monotone"
                    dataKey={key}
                    stroke={themeConfig.colors[index % 6]}
                    dot={false}
                  />
                );
              } else {
                return null;
              }
            })}
          </LineChart>
        </ResponsiveContainer>
      </Grid>
    </Grid>
  );
};
