import React, { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import './FolderView.scss';

interface IFolderView {
  label: string;
  icon?: React.ReactNode;
  component?: React.ReactNode;
  children?: IFolderView[];
  level?: number;
}
const FolderItem = ({ item }: { item: IFolderView }) => {
  const [open, setOpen] = useState(true);
  const level = item.level || 0;
  const padding = level * 16 + 'px';
  return item.children?.length ? (
    <Accordion expanded={open} onChange={() => setOpen(!open)}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        className={'folder-view-accordion'}
        style={{
          flexDirection: 'row-reverse',
          padding: `0 ${padding}`,
        }}
      >
        <Typography paragraph={false}>
          <Grid
            container
            item
            alignItems={'center'}
            className={'folder-view-accordion-title'}
          >
            {item.icon}
            {item.label}
          </Grid>
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        style={{
          flexDirection: 'column',
          padding: 0,
          margin: 0,
        }}
      >
        {item.children.map((child) => (
          <FolderItem item={{ ...child, level: level + 1 }} />
        ))}
      </AccordionDetails>
    </Accordion>
  ) : (
    <>{!!item.component ? item.component : item.label}</>
  );
};

const FolderView = ({ items }: { items: IFolderView[] }) => {
  return (
    <Grid>
      {items.map((item) => (
        <FolderItem item={item} />
      ))}
    </Grid>
  );
};

export default FolderView;
