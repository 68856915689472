import {
  MATERIAL_BASE_TYPE_SUCCESS,
  MATERIAL_DETAILS_START,
  MATERIAL_DETAILS_SUCCESS,
  MATERIAL_RECIPE_SUCCESS,
} from './MaterialDetailsTypes';
import {
  LOADING,
  CLEAR,
} from 'containers/_Default/Notification/NotificationTypes';
import {
  createMaterialRecipe,
  editMaterialRecipe,
  editMaterialSpecification,
  getAllBaseTypeMaterials,
  getAllRecipesByMaterial,
  getMaterial,
} from '../../repository/material';
import {
  ERROR,
  SUCCESS,
} from 'containers/_Default/Notification/NotificationTypes';
import { editMaterial } from 'repository/material';
import { MATERIAL_START } from '../Material/MaterialTypes';

export const fetchMaterial = (params) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: MATERIAL_DETAILS_START,
      });

      dispatch({
        type: LOADING,
        payload: {
          isOpen: true,
          message: 'material_loading',
        },
      });

      const response: any = await getMaterial(params);

      const recipes: any = await getAllRecipesByMaterial(params);

      setTimeout(() => {
        dispatch({
          type: CLEAR,
        });
      }, 1000);

      dispatch({
        type: MATERIAL_DETAILS_SUCCESS,
        payload: {
          ...response.data,
          recipes: [...recipes.data],
        },
      });
    } catch (error) {
      const message =
        error?.response?.data?.message ||
        error?.response?.data?.detail ||
        error?.response?.data?.title ||
        'error';

      dispatch({
        type: ERROR,
        payload: {
          isOpen: true,
          message,
        },
      });
    }
  };
};

export const updateMaterialInfo = (id: number, data: any) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: MATERIAL_DETAILS_START,
      });

      const response = await editMaterial(id, data);
      dispatch({
        type: MATERIAL_DETAILS_SUCCESS,
        payload: response.data,
      });

      dispatch({
        type: SUCCESS,
        payload: {
          isOpen: true,
          message: 'material_updateSuccess',
        },
      });
      return true;
    } catch (error) {
      console.log(error);
      const message =
        error?.response?.data?.message ||
        error?.response?.data?.detail ||
        error?.response?.data?.title ||
        'error';

      dispatch({
        type: ERROR,
        payload: {
          isOpen: true,
          message,
        },
      });
    }
  };
};

export const updateMaterialSpecification = (id: number, data: any) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: MATERIAL_DETAILS_START,
      });

      const response = await editMaterialSpecification(id, data);
      dispatch({
        type: MATERIAL_DETAILS_SUCCESS,
        payload: response.data,
      });

      dispatch({
        type: SUCCESS,
        payload: {
          isOpen: true,
          message: 'material_updateSuccess',
        },
      });
      return true;
    } catch (error) {
      const message =
        error?.response?.data?.message ||
        error?.response?.data?.detail ||
        error?.response?.data?.title ||
        'error';

      dispatch({
        type: ERROR,
        payload: {
          isOpen: true,
          message,
        },
      });
    }
  };
};

export const updateMaterialRecipeAction = (
  materialId: number,
  data: any,
  recipeId: number,
) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: MATERIAL_DETAILS_START,
      });

      const response = await editMaterialRecipe(materialId, data, recipeId);

      dispatch({
        type: MATERIAL_RECIPE_SUCCESS,
        payload: response.data,
      });

      dispatch({
        type: SUCCESS,
        payload: {
          isOpen: true,
          message: 'material_recipeUpdateSuccess',
        },
      });
      return true;
    } catch (error) {
      const message =
        error?.response?.data?.message ||
        error?.response?.data?.detail ||
        error?.response?.data?.title ||
        'error';

      dispatch({
        type: ERROR,
        payload: {
          isOpen: true,
          message,
        },
      });
    }
  };
};

export const createMaterialRecipeAction = (materialId: number, data: any) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: MATERIAL_DETAILS_START,
      });

      const response = await createMaterialRecipe(materialId, data);
      dispatch({
        type: MATERIAL_RECIPE_SUCCESS,
        payload: response.data,
      });

      dispatch({
        type: SUCCESS,
        payload: {
          isOpen: true,
          message: 'material_recipeCreateSuccess',
        },
      });
      return true;
    } catch (error) {
      const message =
        error?.response?.data?.message ||
        error?.response?.data?.detail ||
        error?.response?.data?.title ||
        'error';

      dispatch({
        type: ERROR,
        payload: {
          isOpen: true,
          message,
        },
      });
    }
  };
};

export const fetchAllBaseTypeMaterialsAction = (props) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: MATERIAL_START,
      });

      const response: any = await getAllBaseTypeMaterials(props);
      dispatch({
        type: MATERIAL_BASE_TYPE_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      const message =
        error?.response?.data?.message ||
        error?.response?.data?.detail ||
        error?.response?.data?.title ||
        'error';

      dispatch({
        type: ERROR,
        payload: {
          isOpen: true,
          message,
        },
      });
    }
  };
};
