import React, { useEffect } from 'react';
import { MaterialDetailsView } from './MaterialDetailsView';
import { useSelector, useDispatch } from 'react-redux';
import {
  createMaterialRecipeAction,
  fetchAllBaseTypeMaterialsAction,
  fetchMaterial,
  updateMaterialInfo,
  updateMaterialRecipeAction,
  updateMaterialSpecification,
} from './MaterialDetailsActions';
import {
  MATERIAL_DETAILS_RESET,
  MATERIAL_SPECIFICATION_ADD,
  MATERIAL_SPECIFICATION_REMOVE,
} from './MaterialDetailsTypes';
import { transformRecipePercentageValue } from '../../utils/transform-helpers';
import { useTranslation } from 'react-i18next';

export default (props) => {
  const { t } = useTranslation(['materials', 'common', 'project', 'templates']);
  const dispatch = useDispatch();
  const materialDetails = useSelector((state) => state.materialDetails);

  const getAllBaseTypeMaterials = () => {
    dispatch(
      fetchAllBaseTypeMaterialsAction({ limit: Number.MAX_SAFE_INTEGER }),
    );
  };

  const addSpecification = (specification) => {
    dispatch({
      type: MATERIAL_SPECIFICATION_ADD,
      payload: specification,
    });
  };
  const removeSpecification = (testName) => {
    dispatch({
      type: MATERIAL_SPECIFICATION_REMOVE,
      payload: testName,
    });
  };

  useEffect(() => {
    return () => {
      dispatch({
        type: MATERIAL_DETAILS_RESET,
      });
    };
  }, []);

  const saveSpecification = async (data) => {
    const response = await dispatch(
      updateMaterialSpecification(props.match.params.id, data),
    );
    return response;
  };

  const saveInformation = async (data) => {
    const response = await dispatch(
      updateMaterialInfo(props.match.params.id, data),
    );
    return response;
  };

  const saveRecipeConfig = async (params) => {
    const data = {
      rows: params.rows.filter((recipeRow) => !recipeRow.deleted),
      name: params.name,
    };
    transformRecipePercentageValue(data);
    return await dispatch(
      createMaterialRecipeAction(props.match.params.id, data),
    );
  };

  const editRecipeConfig = async (recipeId, params) => {
    const data = {
      rows: params.rows.filter((recipeRow) => !recipeRow.deleted),
      name: params.name,
    };
    transformRecipePercentageValue(data);
    return await dispatch(
      updateMaterialRecipeAction(props.match.params.id, data, recipeId),
    );
  };

  const goBackAction = () => {
    props.history.push('/dashboard/material');
  };

  const cancelSpecificationAction = () => {
    dispatch(fetchMaterial(props.match.params.id));
  };

  useEffect(() => {
    dispatch(fetchMaterial(props.match.params.id));
  }, [props.match.params.id]);

  useEffect(() => {
    dispatch(
      fetchAllBaseTypeMaterialsAction({ limit: Number.MAX_SAFE_INTEGER }),
    );
  }, []);

  return (
    <MaterialDetailsView
      data={materialDetails.data}
      addSpecification={addSpecification}
      removeSpecification={removeSpecification}
      saveSpecification={saveSpecification}
      cancelSpecificationAction={cancelSpecificationAction}
      saveInformation={saveInformation}
      recipes
      materials={getAllBaseTypeMaterials}
      saveRecipeConfig={editRecipeConfig}
      createRecipeConfig={saveRecipeConfig}
      goBackAction={goBackAction}
      allBaseTypeMaterials={materialDetails.allBaseTypeMaterials.docs}
      t={t}
    />
  );
};
