import API from 'services/api';

export const createMaterial = async (data: any) => {
  return await API.post('/material', data);
};

export const editMaterial = async (id: number, data: any) => {
  return await API.put(`/material/${id}`, data);
};

export const getMaterial = async (id) => {
  return await API.get(`/material/${id}`);
};

export const removeMaterial = async (id) => {
  return await API.delete(`/material/${id}`);
};

export const getMaterials = async (props) => {
  const { limit = 10, page = 0, sort, name, filter = '' } = props;
  return await API.get('/material', {
    params: { limit, page, sort, name, filter },
  });
};

export const getAllBaseTypeMaterials = async (props) => {
  const {
    limit = Number.MAX_SAFE_INTEGER,
    page = 0,
    sort,
    name,
    filter = '',
  } = props;
  return await API.get('/material/type-base', {
    params: { limit, page, sort, name, filter },
  });
};

export const getAllRecipesByMaterial = async (id) => {
  return await API.get(`/material/${id}/recipes`);
};

export const editMaterialSpecification = async (id, data) => {
  return await API.put(`/material/${id}/specification`, data);
};

export const editMaterialRecipe = async (materialId, data, recipeId) => {
  return await API.put(`/material/${materialId}/recipe/${recipeId}`, data);
};

export const createMaterialRecipe = async (materialId, data) => {
  return await API.put(`/material/${materialId}/recipe`, data);
};

export const getMaterialSpecifications = async (materialId) => {
  return await API.get(`/material/${materialId}/specification`);
};
