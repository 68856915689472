import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ProjectDataView } from './ProjectDataView';
import { fetchProjectOnDataScreen } from './ProjectDataActions';
import { PROJECT_DATA_RESET_STATE } from './ProjectDataTypes';

import './ProjectData.scss';
import { useTranslation } from 'react-i18next';

export default ({ history }) => {
  const dispatch = useDispatch();
  const projectDataState = useSelector((state: any) => state.projectData);
  const { id } = useParams();

  const { t } = useTranslation([
    'projectData',
    'projectDocumentation',
    'common',
    'results',
  ]);
  useEffect(() => {
    dispatch(fetchProjectOnDataScreen(id));
    return () =>
      dispatch({
        type: PROJECT_DATA_RESET_STATE,
      });
  }, []);

  const redirectToProjectEdit = () => {
    history.push(window.location.pathname.replace('/data', ''));
  };
  return (
    <ProjectDataView
      projectData={projectDataState}
      projectId={id}
      goBackToProjectEdit={redirectToProjectEdit}
      t={t}
    />
  );
};
