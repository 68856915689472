import React, { useReducer, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Badge,
  Checkbox,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  Cancel,
  Comment,
  Description,
  Error,
  Warning,
  Settings,
  Visibility,
  AssignmentTurnedIn,
} from '@material-ui/icons';
interface IResultView {
  result: {
    _id: string;
    name: string;
    resultStatus: Number;
    comments: any[];
    resultExclusionReason: string;
    test: {
      template: {
        name: string;
      };
    };
    device: {
      manualInputOnly: boolean;
    };
  };
  selectResult: Function;
  deselectResult: Function;
  fastPreview: any;
  configureTemplate?: any;
  exclude: any;
  comment: any;
  hasResults?: boolean;
  t?: Function;
}

export enum ResultStatus {
  EXCLUDED,
  OK,
}

export enum ResultExclusionReason {
  BAD_MATERIAL = 'badMaterial',
  BAD_TEST = 'badTest',
  OTHER = 'other',
}
const ResultView = ({
  result,
  selectResult,
  deselectResult,
  fastPreview,
  configureTemplate,
  exclude,
  comment,
  hasResults = false,
  t = (string) => 'Missing translation',
}: IResultView) => {
  const projectData = useSelector((state: any) => state.projectData);

  return (
    <Grid
      item
      container
      style={{
        width: 'calc(100% - 35px)',
        marginLeft: 'auto',
        paddingRight: '15px',
      }}
    >
      <Grid container item xs={6} alignItems={'center'} justify={'flex-start'}>
        <Checkbox
          disabled={!result._id || !hasResults}
          checked={projectData.selectedResults.includes(result._id)}
          onClick={() => {
            if (!projectData.selectedResults.includes(result._id)) {
              selectResult(result._id);
            } else {
              deselectResult(result._id);
            }
          }}
        />{' '}
        <Description style={{ marginRight: '5px' }} color={'secondary'} />
        <Typography>{result.name}</Typography>
        {result.test.template ? (
          <Grid item container xs={1} alignItems="center">
            <Tooltip
              title={t('resultView_templateTooltip', {
                template: result?.test?.template?.name,
              })}
              arrow
              placement="right"
            >
              <AssignmentTurnedIn color={'secondary'} className="m-l-15" />
            </Tooltip>
          </Grid>
        ) : null}
        {result.resultStatus == ResultStatus.EXCLUDED && (
          <Grid item container xs={1} alignItems={'center'}>
            {result.resultExclusionReason ==
              ResultExclusionReason.BAD_MATERIAL && (
              <Tooltip title={t('resultView_problemWithMaterial')} arrow>
                <Error color={'secondary'} className="m-l-10" />
              </Tooltip>
            )}
            {result.resultExclusionReason == ResultExclusionReason.BAD_TEST && (
              <Tooltip title={t('resultView_problemWithTest')} arrow>
                <Warning color={'secondary'} className="m-l-10" />
              </Tooltip>
            )}
            {result.resultExclusionReason == ResultExclusionReason.OTHER && (
              <Tooltip title={t('resultView_excludedResult')} arrow>
                <Warning color={'secondary'} className="m-l-10" />
              </Tooltip>
            )}
          </Grid>
        )}
      </Grid>
      {result._id && hasResults ? (
        <Grid
          container
          xs={6}
          alignItems={'center'}
          justify={'flex-end'}
          spacing={2}
        >
          {!result?.device?.manualInputOnly ? (
            <Grid item xs={1}>
              <Tooltip title={t('resultView_configureTemplateTooltip')} arrow>
                <IconButton
                  className={'icons'}
                  aria-label="edit"
                  color="primary"
                  size="small"
                  disableFocusRipple
                  disableRipple
                  disableTouchRipple
                  onClick={configureTemplate}
                >
                  <Settings color="secondary" />
                </IconButton>
              </Tooltip>
            </Grid>
          ) : null}
          <Grid item xs={1}>
            <Tooltip title={t('resultView_previewResultTooltip')} arrow>
              <IconButton
                className={'icons'}
                aria-label="edit"
                color="primary"
                size="small"
                disableFocusRipple
                disableRipple
                disableTouchRipple
                onClick={fastPreview}
              >
                <Visibility color="secondary" />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={1}>
            <IconButton
              className={'icons'}
              aria-label="edit"
              color="secondary"
              size="small"
              disableFocusRipple
              disableRipple
              disableTouchRipple
              onClick={() => exclude(result._id)}
            >
              <Tooltip title={t('resultView_markResultTooltip')} arrow>
                <Cancel color={'secondary'} />
              </Tooltip>
            </IconButton>
          </Grid>
          <Grid item xs={1}>
            <IconButton
              className={'icons'}
              aria-label="edit"
              color="secondary"
              size="small"
              disableFocusRipple
              disableRipple
              disableTouchRipple
              onClick={() => comment(result.comments)}
            >
              <Tooltip title={t('resultView_resultCommentsTooltip')} arrow>
                <Badge badgeContent={result.comments?.length} color="primary">
                  <Comment color={'secondary'} />
                </Badge>
              </Tooltip>
            </IconButton>
          </Grid>
        </Grid>
      ) : null}
    </Grid>
  );
};
export default ResultView;
