import React, { ChangeEvent } from 'react';
import { MentionsInput, Mention } from 'react-mentions';

import './MentionPerson.scss';

interface MentionPersonProps {
  disabled?: boolean;
  value: String;
  onChange?: Function;
  onBlur?: Function;
  users?: Array<any>;
}

function MentionPerson({
  value,
  users,
  onChange,
  onBlur,
  disabled = false,
}: MentionPersonProps) {
  const userMentionData = users?.map(({ email, _id }) => ({
    id: _id,
    display: email,
  }));

  const disabledStyle = {
    borderBottom: '1px solid silver',
    marginBottom: '100px',
  };

  return (
    <div className="mention-person">
      <MentionsInput
        value={value}
        disabled={disabled}
        onChange={onChange}
        onBlur={onBlur}
        markup="@{{__type__||__id__||__display__}}"
        placeholder=""
        className="mentions"
        style={!disabled && disabledStyle}
      >
        <Mention
          type="user"
          trigger="@"
          data={userMentionData}
          className="mentions__mention"
        />
      </MentionsInput>
    </div>
  );
}

export default MentionPerson;
