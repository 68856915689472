import React, { useEffect, useState } from 'react';
import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { PageTitle } from 'components';
import { MaterialRecipeSelect } from './components/MaterialRecipeSelect';

export const MaterialRecipeComparisonView = (props) => {
  const {
    goBackAction,
    getAllRecipesByMaterial,
    materialId,
    materialRecipesData,
    t,
  } = props;

  const materialRecipesRows = materialRecipesData.flatMap((item) => item.rows);
  let recipePositionCounter: number = 1;
  const uniqueMaterialRecipesRows = [] as any;
  const materialNameSet = new Set() as any;

  for (const materialRecipeRow of materialRecipesRows) {
    if (!materialNameSet.has(materialRecipeRow?.materialName)) {
      materialNameSet.add(materialRecipeRow?.materialName);
      uniqueMaterialRecipesRows?.push(materialRecipeRow);
    }
  }

  const findPercentageInRecipe = (materialId, recipe) => {
    return (
      recipe
        .find(
          (recipeItem) =>
            `${recipeItem.materialName}_${recipeItem.materialNumber}` ===
            materialId,
        )
        ?.percentage?.toFixed(2) || '0.00'
    );
  };

  const [firstRecipe, setFirstRecipe] = useState(
    materialRecipesData[0] || null,
  );
  const [secondRecipe, setSecondRecipe] = useState(
    materialRecipesData[1] || null,
  );
  const [thirdRecipe, setThirdRecipe] = useState(
    materialRecipesData[2] || null,
  );
  const [fourthRecipe, setFourthRecipe] = useState(
    materialRecipesData[3] || null,
  );
  const [fifthRecipe, setFifthRecipe] = useState(
    materialRecipesData[4] || null,
  );
  const [tableItemsList, setTableItemsList] = useState([
    firstRecipe,
    secondRecipe,
    thirdRecipe,
    fourthRecipe,
    fifthRecipe,
  ]);

  const [options, setOptions] = useState([] as any[]);

  useEffect(() => {
    getAllRecipesByMaterial(materialId);
  }, [materialId]);

  useEffect(() => {
    setFirstRecipe(materialRecipesData[0]);
    setSecondRecipe(materialRecipesData[1]);
    setThirdRecipe(materialRecipesData[2]);
    setFourthRecipe(materialRecipesData[3]);
    setFifthRecipe(materialRecipesData[4]);
    setTableItemsList([
      materialRecipesData[0] || null,
      materialRecipesData[1] || null,
      materialRecipesData[2] || null,
      materialRecipesData[3] || null,
      materialRecipesData[4] || null,
    ]);

    setOptions(
      materialRecipesData.map((item) => ({
        label: item.name,
        value: item,
      })),
    );
  }, [materialRecipesData]);

  return (
    <div>
      <PageTitle title={materialRecipesData[0]?.material?.name}>
        <Button color="primary" onClick={() => goBackAction()}>
          <Typography variant="body2">{t('goBack')}</Typography>
        </Button>
      </PageTitle>
      <Grid container className={'box-holder'} style={{ overflowY: 'auto' }}>
        <Grid container item justify={'space-between'} alignItems={'center'}>
          <Grid item container xs={4}>
            <Typography variant={'h3'}>{t('recipeComparison')}</Typography>
          </Grid>
        </Grid>
        <TableContainer component={Paper} style={{ overflow: 'visible' }}>
          <Table aria-label="simple table" style={{ tableLayout: 'fixed' }}>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell>
                  {firstRecipe && (
                    <MaterialRecipeSelect
                      options={options}
                      onChange={(value) => setFirstRecipe(value)}
                      isDisabled={false}
                      label={'Recipe 1'}
                      defaultValue={firstRecipe._id}
                    />
                  )}
                </TableCell>
                <TableCell>
                  {secondRecipe && (
                    <MaterialRecipeSelect
                      options={options}
                      onChange={(value) => setSecondRecipe(value)}
                      isDisabled={false}
                      label={'Recipe 2'}
                      defaultValue={secondRecipe._id}
                    />
                  )}
                </TableCell>
                <TableCell>
                  {thirdRecipe && (
                    <MaterialRecipeSelect
                      options={options}
                      onChange={(value) => setThirdRecipe(value)}
                      isDisabled={false}
                      label={'Recipe 3'}
                      defaultValue={thirdRecipe._id}
                    />
                  )}
                </TableCell>
                <TableCell>
                  {fourthRecipe && (
                    <MaterialRecipeSelect
                      options={options}
                      onChange={(value) => setFourthRecipe(value)}
                      isDisabled={false}
                      label={'Recipe 4'}
                      defaultValue={fourthRecipe._id}
                    />
                  )}
                </TableCell>
                <TableCell>
                  {fifthRecipe && (
                    <MaterialRecipeSelect
                      options={options}
                      onChange={(value) => setFifthRecipe(value)}
                      isDisabled={false}
                      label={'Recipe 5'}
                      defaultValue={fifthRecipe._id}
                    />
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('position')}</TableCell>
                <TableCell>{t('materialNo')}</TableCell>
                <TableCell>{t('name')}</TableCell>
                {firstRecipe && <TableCell>{t('percentage')}</TableCell>}
                {secondRecipe && <TableCell>{t('percentage')}</TableCell>}
                {thirdRecipe && <TableCell>{t('percentage')}</TableCell>}
                {fourthRecipe && <TableCell>{t('percentage')}</TableCell>}
                {fifthRecipe && <TableCell>{t('percentage')}</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {(uniqueMaterialRecipesRows || []).map((item, index) => {
                return (
                  <TableRow>
                    <TableCell>{recipePositionCounter++}</TableCell>
                    <TableCell
                      style={{
                        whiteSpace: 'nowrap',
                        maxWidth: '300px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {item.materialNumber}
                    </TableCell>
                    <TableCell
                      style={{
                        whiteSpace: 'nowrap',
                        maxWidth: '300px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {item.materialName}
                    </TableCell>
                    {firstRecipe && (
                      <TableCell>
                        {findPercentageInRecipe(
                          `${item.materialName}_${item.materialNumber}`,
                          firstRecipe.rows,
                        )}
                      </TableCell>
                    )}
                    {secondRecipe && (
                      <TableCell>
                        {findPercentageInRecipe(
                          `${item.materialName}_${item.materialNumber}`,
                          secondRecipe.rows,
                        )}
                      </TableCell>
                    )}
                    {thirdRecipe && (
                      <TableCell>
                        {findPercentageInRecipe(
                          `${item.materialName}_${item.materialNumber}`,
                          thirdRecipe.rows,
                        )}
                      </TableCell>
                    )}
                    {fourthRecipe && (
                      <TableCell>
                        {findPercentageInRecipe(
                          `${item.materialName}_${item.materialNumber}`,
                          fourthRecipe.rows,
                        )}
                      </TableCell>
                    )}
                    {fifthRecipe && (
                      <TableCell>
                        {findPercentageInRecipe(
                          `${item.materialName}_${item.materialNumber}`,
                          fifthRecipe.rows,
                        )}
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </div>
  );
};
