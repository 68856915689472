import React, { useReducer, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Badge,
  Checkbox,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  Cancel,
  Comment,
  Description,
  Error,
  Warning,
  Settings,
  Visibility,
  AssignmentTurnedIn,
} from '@material-ui/icons';
const DocumentView = ({
  document,
  openModal = () => {},
  comment = () => {},
  t = (string) => 'Missing translation',
}: any) => {
  return (
    <Grid
      item
      container
      style={{
        width: 'calc(100% - 70px)',
        marginLeft: 'auto',
        paddingRight: '15px',
        marginTop: '10px',
      }}
    >
      <Grid container item xs={6} alignItems={'center'} justify={'flex-start'}>
        <Description style={{ marginRight: '5px' }} color={'secondary'} />
        <Typography>
          {t(`${document.documentConfig.name}`, {
            ns: 'projectDocumentation',
          })}
        </Typography>
      </Grid>
      <Grid
        container
        xs={6}
        alignItems={'center'}
        justify={'flex-end'}
        spacing={2}
      >
        <Grid item xs={1}>
          <Tooltip title="Edit document" arrow>
            <IconButton
              className={'icons'}
              aria-label="edit"
              color="primary"
              size="small"
              disableFocusRipple
              disableRipple
              disableTouchRipple
              onClick={() => openModal(document)}
            >
              <Visibility color="secondary" />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={1}>
          <IconButton
            className={'icons'}
            aria-label="edit"
            color="secondary"
            size="small"
            disableFocusRipple
            disableRipple
            disableTouchRipple
            onClick={() => comment(document.comments)}
          >
            <Tooltip title={t('documentCommentsTooltip')} arrow>
              <Badge badgeContent={document.comments?.length} color="primary">
                <Comment color={'secondary'} />
              </Badge>
            </Tooltip>
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default DocumentView;
