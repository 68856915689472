import React, { useEffect, useState } from 'react';
import {
  Button,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { getFieldFromType } from '../../../components/Forms/FieldTypes';
import { Controller, useForm } from 'react-hook-form';
import DeleteIcon from '@material-ui/icons/Delete';
import ReactSelect from 'react-select';
import { useHistory, useLocation } from 'react-router-dom';
import { transformRecipePercentageValue } from '../../../utils/transform-helpers';
import '../MaterialDetails.scss';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n';

enum MODES {
  VIEW = 1,
  CREATE = 2,
  EDIT = 3,
}

const RecipeTable = ({
  editMode,
  addRow,
  data,
  form,
  t,
  deleteRow = (row) => {},
  allBaseTypeMaterials = [],
}) => {
  const materialNumberOptions = allBaseTypeMaterials.map((option: any) => ({
    label: option.number,
    value: option.number,
    id: option._id,
  }));
  const materialNameOptions = allBaseTypeMaterials.map((option: any) => ({
    label: option.name,
    value: option.name,
    id: option._id,
  }));

  const findSelectedAndUpdateNumber = (field: string, value) => {
    const material: any = allBaseTypeMaterials.find(
      (material: any) => material.name === value,
    );

    const [index, fieldName] = field.split('.');

    form.setValue(`${index}.materialNumber`, material?.number);
  };

  const findSelectedAndUpdateName = (field: string, value) => {
    const material: any = allBaseTypeMaterials.find(
      (material: any) => material.number === value,
    );

    const [index, fieldName] = field.split('.');

    form.setValue(`${index}.materialName`, material?.name);
  };

  const [filteredMaterialNameOptions, setFilteredMaterialNameOptions] =
    useState([] as any);
  const [filteredMaterialNumberOptions, setFilteredMaterialNumberOptions] =
    useState([] as any);

  useEffect(() => {
    const values = [] as any;

    Object.entries(form.getValues()).forEach((entry) => {
      if (entry) {
        const [key, value] = entry;
        if (value) {
          values.push(value);
        }
      }
    });

    if (values.length) {
      const filteredNameOptions = materialNameOptions.filter(
        ({ label: label }) =>
          !values.some(
            ({ materialName: materialName }) => label === materialName,
          ),
      );

      const filteredNumberOptions = materialNumberOptions.filter(
        ({ label: label }) =>
          !values.some(
            ({ materialNumber: materialNumber }) => label === materialNumber,
          ),
      );

      setFilteredMaterialNameOptions([...filteredNameOptions]);
      setFilteredMaterialNumberOptions([...filteredNumberOptions]);
    }
  }, [form, editMode]);

  return (
    <Grid container>
      <TableContainer component={Paper} style={{ overflowX: 'visible' }}>
        <Table aria-label="simple table" style={{ tableLayout: 'fixed' }}>
          <TableHead>
            <TableRow>
              <TableCell>{t('position')}</TableCell>
              <TableCell>{t('materialNo')}</TableCell>
              <TableCell>{t('materialName')}</TableCell>
              <TableCell>{t('percentage')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(data.rows || []).map(
              (row, index) =>
                !row.deleted && (
                  <TableRow>
                    <TableCell>
                      {getFieldFromType(
                        form,
                        {
                          fieldName: `${index}.position`,
                          label: '',
                          type: 'table-text',
                          required: true,
                          span: 6,
                          error: ' ',
                        } as any,
                        data._id + '-position',
                        data.rows,
                        t,
                        false,
                      )}
                    </TableCell>
                    <TableCell
                      className={
                        editMode && row.generatedNow
                          ? 'table-cell-edit-mode'
                          : 'table-cell-view-mode'
                      }
                    >
                      {getFieldFromType(
                        form,
                        {
                          fieldName: `${index}.materialNumber`,
                          type: 'materials-number-search-select',
                          auxiliaryLabel: 'Material',
                          options: filteredMaterialNumberOptions,
                          required: false,
                          className: 'login-input',
                          span: 11,
                          index: index,
                        } as any,
                        data._id + '-materialNumber',
                        data.rows,
                        t,
                        editMode && row.generatedNow,
                        false,
                        (fieldName, value) =>
                          findSelectedAndUpdateName(fieldName, value),
                      )}
                    </TableCell>
                    <TableCell
                      className={
                        editMode && row.generatedNow
                          ? 'table-cell-edit-mode'
                          : 'table-cell-view-mode'
                      }
                    >
                      {getFieldFromType(
                        form,
                        {
                          fieldName: `${index}.materialName`,
                          type: 'materials-name-search-select',
                          auxiliaryLabel: 'Material',
                          options: filteredMaterialNameOptions,
                          required: false,
                          className: 'login-input',
                          span: 11,
                          index: index,
                        } as any,
                        data._id + '-materialName',
                        data.rows,
                        t,
                        editMode && row.generatedNow,
                        false,
                        (fieldName, value) =>
                          findSelectedAndUpdateNumber(fieldName, value),
                      )}
                    </TableCell>
                    <TableCell>
                      <Grid
                        container
                        alignItems={'center'}
                        justify="flex-start"
                        xs={6}
                      >
                        <Grid item xs={10}>
                          {getFieldFromType(
                            form,
                            {
                              fieldName: `${index}.percentage`,
                              label: '',
                              type: 'table-text',
                              required: false,
                              subType: 'Float',
                              span: 12,
                              error: ' ',
                            } as any,
                            data._id + '-percentage',
                            data.rows,
                            t,
                            editMode,
                          )}
                        </Grid>
                        <Grid item xs={2}>
                          %
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell>
                      {getFieldFromType(
                        form,
                        {
                          fieldName: `${index}.deleted`,
                          label: '',
                          type: 'hidden',
                          required: true,
                          span: 6,
                          error: ' ',
                        } as any,
                        data._id + '-deleted',
                        data.rows,
                        t,
                        editMode && row.generatedNow,
                      )}
                    </TableCell>
                    <TableCell>
                      <Controller
                        name={`${index}.deleted`}
                        defaultValue={row.deleted}
                        render={() => (
                          <TextField
                            name={`${index}.deleted`}
                            type={'hidden'}
                            value={row.deleted}
                          />
                        )}
                        control={form.control}
                      />
                      {editMode && row.generatedNow && (
                        <IconButton
                          // key={index}
                          aria-label="edit"
                          color="secondary"
                          size="small"
                          disableFocusRipple
                          disableRipple
                          disableTouchRipple
                          onClick={() => {
                            deleteRow(index);
                            form.setValue(`${index}.deleted`, true);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ),
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid container justify={'flex-end'} key={'edit-' + editMode}>
        {editMode && (
          <IconButton
            // key={index}
            aria-label="edit"
            color="secondary"
            size="small"
            disableFocusRipple
            disableRipple
            disableTouchRipple
            onClick={() =>
              addRow({
                range: {},
                generatedNow: true,
                position:
                  (data.rows?.filter((iteItem) => !iteItem.deleted).length ||
                    0) + 1,
              })
            }
          >
            <AddIcon />
          </IconButton>
        )}
      </Grid>
    </Grid>
  );
};

const customStyles = {
  option: (provided) => ({
    ...provided,
  }),
  control: (provided, state) => ({
    ...provided,
    border: 0,
    borderBottom: '2px solid #e0e0e0',
    borderColor: 'white',
    boxShadow: '0',
    '&:hover': {
      borderBottom: '2px solid #e0e0e0',
    },
    padding: '0px 0px',
    margin: '0px 0px',
    borderRadius: 0,
    width: i18n.language === 'en' ? '300px' : '200px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0px 0px',
  }),
};
const ViewMode = ({
  data,
  changeSelectedRecipe,
  setMode,
  options,
  material,
  allBaseTypeMaterials,
  t,
}) => {
  const form = useForm();
  const history = useHistory();
  const location = useLocation();

  return (
    <Grid container>
      <form style={{ width: '100%' }}>
        <Grid
          item
          container
          xs={12}
          justify={'space-between'}
          alignItems={'center'}
        >
          <ReactSelect
            options={options}
            onChange={(e) => {
              changeSelectedRecipe(e.value);
            }}
            styles={customStyles}
            defaultValue={options[0]}
          />
          <Grid
            item
            container
            xs={i18n.language === 'en' ? 6 : 8}
            justify={'flex-end'}
            spacing={2}
          >
            <Button
              style={{ marginRight: '15px' }}
              color="primary"
              onClick={() => {
                history.push(location.pathname + '/recipe/comparison');
              }}
            >
              <Typography variant="body2">{t('compare')}</Typography>
            </Button>
            <Button
              style={{ marginRight: '15px' }}
              color="default"
              onClick={() => {
                if (options?.length) {
                  changeSelectedRecipe(data);
                }
                setMode(MODES.CREATE);
              }}
            >
              <Typography variant="body2">
                {t('add', { ns: 'common' })}
              </Typography>
            </Button>
            <Button
              color="primary"
              variant={data.rows?.length ? 'contained' : 'text'}
              disabled={!data.rows?.length}
              onClick={() => {
                setMode(MODES.EDIT);
                changeSelectedRecipe(data);
              }}
            >
              {t('edit')}
            </Button>
          </Grid>
        </Grid>
        <RecipeTable
          editMode={false}
          data={data || []}
          addRow={() => {}}
          form={form}
          allBaseTypeMaterials={allBaseTypeMaterials}
          t={t}
        />
      </form>
      <Grid item container xs={12} justify={'flex-end'}>
        <Typography variant={'body2'}>
          {t('totalPercentage')}
          {(
            data.rows?.reduce(
              (acc, item) => acc + parseFloat(item.percentage || 0),
              0,
            ) || 0
          ).toFixed(2)}
          %
        </Typography>
      </Grid>
    </Grid>
  );
};

const EditMode = ({
  data,
  saveMethod,
  addRow,
  setMode,
  setCurrentRecipe,
  deleteRow = (row) => {},
  allBaseTypeMaterials,
  t,
}) => {
  const form = useForm();
  const watcher = form.watch();

  return (
    <Grid item container xs={12} lg={12}>
      <form
        style={{ width: '100%' }}
        onSubmit={form.handleSubmit(async (dataSubmitted) => {
          const isFormValid = await form.trigger();
          const name = dataSubmitted.name;
          delete dataSubmitted.name;
          const payload = {
            name: data.name,
            rows: Object.values(dataSubmitted),
          };
          saveMethod(data._id, payload);
        })}
      >
        <Grid container item xs={12}>
          <Grid
            item
            container
            xs={12}
            justify={'space-between'}
            alignItems={'center'}
          >
            {getFieldFromType(
              form,
              {
                fieldName: `name`,
                label: 'Recipe Name',
                type: 'text',
                required: true,
                span: 6,
                disabled: true,
                defaultValue: data.name,
                error: ' ',
              } as any,
              data.name + '-text',
              data,
              t,
              false,
            )}
            <Grid item container xs={6} justify={'flex-end'} spacing={2}>
              <Button
                style={{ marginRight: '15px' }}
                color="default"
                variant="contained"
                onClick={() => {
                  setCurrentRecipe({
                    ...data,
                    rows: (data.rows || []).filter(
                      (config) => !config.generatedNow,
                    ),
                  });
                  setMode(MODES.VIEW);
                }}
              >
                {t('cancel', { ns: 'common' })}
              </Button>
              <Button color="default" variant="outlined" type={'submit'}>
                {t('save')}
              </Button>
            </Grid>
          </Grid>
          <RecipeTable
            editMode={true}
            data={data}
            addRow={addRow}
            form={form}
            t={t}
            deleteRow={deleteRow}
            allBaseTypeMaterials={allBaseTypeMaterials}
          />
        </Grid>
      </form>
      <Grid item container xs={12} justify={'flex-end'}>
        <Typography variant={'body2'}>
          {t('totalPercentage')}
          {(form.formState.isDirty
            ? (Object.values(watcher) || []).reduce(
                (acc, item) => acc + parseFloat(item?.percentage || 0),
                0,
              )
            : (data.rows || []).reduce(
                (acc, item) => acc + parseFloat(item.percentage || 0),
                0,
              )
          ).toFixed(2)}
          %
        </Typography>
      </Grid>
    </Grid>
  );
};

const CreateMode = ({
  data,
  saveMethod,
  addRow,
  deleteRow = (row) => {},
  setMode,
  setCurrentRecipe,
  allBaseTypeMaterials,
  t,
}) => {
  const form = useForm();
  const watcher = form.watch();
  return (
    <Grid item container xs={12} lg={12}>
      <form
        style={{ width: '100%' }}
        onSubmit={form.handleSubmit(async (data) => {
          const isFormValid = await form.trigger();
          const name = data.name;
          delete data.name;
          const payload = {
            name,
            rows: Object.values(data),
          };
          saveMethod(payload);
        })}
      >
        <Grid container item xs={12}>
          <Grid
            item
            container
            xs={12}
            justify={'space-between'}
            alignItems={'center'}
          >
            {getFieldFromType(
              form,
              {
                fieldName: `name`,
                label: 'Recipe Name',
                type: 'text',
                required: true,
                span: 6,
                error: ' ',
              } as any,
              data._id + '-text',
              {},
              t,
              true,
            )}
            <Grid item container xs={6} justify={'flex-end'} spacing={2}>
              <Button
                style={{ marginRight: '15px' }}
                color="default"
                variant="contained"
                onClick={() => {
                  setCurrentRecipe({
                    ...data,
                    rows: (data.rows || []).filter(
                      (config) => !config.generatedNow,
                    ),
                  });
                  setMode(MODES.VIEW);
                }}
              >
                {t('cancel', { ns: 'common' })}
              </Button>
              <Button color="default" variant="outlined" type={'submit'}>
                {t('save')}
              </Button>
            </Grid>
          </Grid>
          <RecipeTable
            editMode={true}
            data={data}
            addRow={addRow}
            form={form}
            t={t}
            deleteRow={deleteRow}
            allBaseTypeMaterials={allBaseTypeMaterials}
          />
        </Grid>
        <Grid item container justify={'flex-end'}>
          <Typography variant={'body2'}>
            {t('totalPercentage')}
            {(form.formState.isDirty
              ? (Object.values(watcher) || []).reduce(
                  (acc, item) => acc + parseFloat(item?.percentage || 0),
                  0,
                )
              : (data.rows || []).reduce(
                  (acc, item) => acc + parseFloat(item.percentage || 0),
                  0,
                )
            ).toFixed(2)}
            %
          </Typography>
        </Grid>
      </form>
    </Grid>
  );
};
export const RecipeSection = ({
  material,
  allBaseTypeMaterials,
  editRecipeConfig,
  createRecipeConfig,
  t,
}) => {
  const { i18n } = useTranslation();
  const [mode, setMode] = useState(MODES.VIEW);
  const [currentRecipe, setCurrentRecipe] = useState({
    recipes: [],
  } as any);
  const history = useHistory();
  const form = useForm();

  useEffect(() => {
    if (material.recipes?.length) {
      setCurrentRecipe(material.recipes[0]);
      setMode(MODES.VIEW);
    }
  }, [material]);

  const handleRecipeSelect = (value) => {
    setCurrentRecipe(value);
  };

  const handleDeleteRow = (indexToDelete) => {
    let copiedState = [...currentRecipe.rows];
    let nonDeletedItems = 0;

    copiedState[indexToDelete].deleted = true;
    copiedState = copiedState.map((stateItem) => {
      if (!stateItem.deleted) {
        nonDeletedItems += 1;
      }
      return {
        ...stateItem,
        position: nonDeletedItems,
      };
    });

    setCurrentRecipe({
      ...currentRecipe,
      rows: copiedState,
    });
  };
  if (mode === MODES.VIEW && currentRecipe._id) {
    transformRecipePercentageValue(currentRecipe);
  }
  return (
    <Grid className={'box-holder'} style={{ maxWidth: '95%' }}>
      <Grid item container>
        <Typography variant={'h3'}>{t('recipe')}</Typography>
        {mode === MODES.VIEW && (
          <ViewMode
            allBaseTypeMaterials={allBaseTypeMaterials || []}
            data={currentRecipe}
            changeSelectedRecipe={handleRecipeSelect}
            setMode={setMode}
            material={material}
            options={(material?.recipes || []).map((recipe) => ({
              label: recipe.name,
              value: recipe,
            }))}
            t={t}
          />
        )}
        {mode === MODES.EDIT && (
          <EditMode
            data={currentRecipe}
            setCurrentRecipe={setCurrentRecipe}
            setMode={setMode}
            deleteRow={handleDeleteRow}
            addRow={(row) => {
              setCurrentRecipe({
                ...(currentRecipe || {}),
                rows: [...(currentRecipe?.rows || []), row],
              });
            }}
            saveMethod={editRecipeConfig}
            allBaseTypeMaterials={allBaseTypeMaterials || []}
            t={t}
          />
        )}
        {mode === MODES.CREATE && (
          <CreateMode
            setMode={setMode}
            data={currentRecipe}
            setCurrentRecipe={setCurrentRecipe}
            deleteRow={handleDeleteRow}
            addRow={(row) => {
              setCurrentRecipe({
                ...(currentRecipe || {}),
                rows: [...(currentRecipe?.rows || []), row],
              });
            }}
            saveMethod={createRecipeConfig}
            allBaseTypeMaterials={allBaseTypeMaterials || []}
            t={t}
          />
        )}
      </Grid>
    </Grid>
  );
};
