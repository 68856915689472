import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useForm, FormProvider } from 'react-hook-form';

interface IProjectDataModal {
  isOpen: boolean;
  closeModal: (event: object, reason?: string) => void;
  closeOKModal: (event: object, reason?: string) => void;
  submitFormFunction: (data: object) => any;
  title?: string;
  disableActions: boolean;
  maxWidth;
  hideDivider?: boolean;
  hideSubmit?: boolean;
  hideCancel?: boolean;
  submitButtonText?: string;
  cancelButtonText?: string;
  t: any;
}

const ProjectDataModal: React.FunctionComponent<IProjectDataModal> = ({
  isOpen = false,
  closeModal = () => {},
  closeOKModal = () => {},
  submitFormFunction = () => {},
  title,
  disableActions = false,
  maxWidth = 'xs',
  hideDivider = false,
  hideSubmit = false,
  hideCancel = false,
  submitButtonText = false,
  cancelButtonText = false,
  children,
  t
}) => {
  const methods = useForm();
  const commentData = methods.watch('comment');
  const nameData = methods.watch('name');
  const excludeData = methods.watch('comments');

  return (
    <Dialog
      open={isOpen}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      fullWidth
      maxWidth={maxWidth}
    >
      <FormProvider {...methods}>
        <form>
          {title !== '' ? (
            <DialogTitle className={hideDivider ? 'dialog-title' : ''}>
              <Grid
                item
                container
                justify={'space-between'}
                alignItems={'center'}
              >
                <Typography variant={'h4'}>{title}</Typography>{' '}
                <IconButton onClick={closeModal}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </DialogTitle>
          ) : null}
          <DialogContent className="dialog-content">{children}</DialogContent>
          {!disableActions && (
            <DialogActions>
              {!hideCancel && (
                <Button onClick={closeModal} variant="contained">
                  {cancelButtonText ? cancelButtonText : t('common:cancel')}
                </Button>
              )}
              {!hideSubmit && (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={async (event) => {
                    event.preventDefault();
                    const isFormValid = await methods.trigger();
                    if (isFormValid) {
                      const data = methods.getValues();
                      try {
                        await submitFormFunction(data);
                        closeOKModal(event, '');
                        methods.reset({});
                      } catch (e) {}
                    }
                  }}
                  disabled={
                    (!commentData && !nameData && !excludeData) ||
                    commentData?.trim()?.length === 0 ||
                    excludeData?.trim()?.length === 0
                  }
                  type="submit"
                >
                  { t(submitButtonText ? submitButtonText :'common:submit')}
                </Button>
              )}
            </DialogActions>
          )}
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default ProjectDataModal;
