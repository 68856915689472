import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { format } from 'date-fns';
import MentionPerson from '../MentionPerson/MentionPerson';

import './Comments.scss';

interface IComment {
  _id: string;
  createdBy: string;
  comment: string;
  datePosted: string;
  type: string;
}

enum COMMENT_TYPES {
  NORMAL = 'normal',
  COMPLETE = 'complete',
  EXCLUDE = 'exclude',
}

const Comment = (data: IComment) => {
  const generateClasses = () => {
    const base = 'single-comment';
    switch (data.type) {
      case COMMENT_TYPES.COMPLETE:
        return `${base} completed-comment`;
      case COMMENT_TYPES.EXCLUDE:
        return `${base} excluded-comment`;
      default:
        return base;
    }
  };

  return (
    <Grid item container xs={12} className={generateClasses()}>
      <Typography variant={'body2'} className="comment-info">
        {` ${format(new Date(data.datePosted), 'dd/MM/yyyy - HH:mm')} ${
          data.createdBy
        } :`}
      </Typography>
      <Typography variant={'body1'} className="comment-text">
        <MentionPerson disabled={true} value={data.comment} />
      </Typography>
    </Grid>
  );
};

export default Comment;
