export const MATERIAL_DETAILS_START = 'material/material-details-start';
export const MATERIAL_DETAILS_SUCCESS = 'material/material-details-success';
export const MATERIAL_DETAILS_ERROR = 'material/material-details-error';

export const MATERIAL_SPECIFICATION_ADD = 'material/material-specification-add';
export const MATERIAL_SPECIFICATION_REMOVE =
  'material/material-specification-remove';

export const MATERIAL_DETAILS_RESET = 'material/material-details-reset';

export const MATERIAL_RECIPE_SUCCESS = 'material/material-recipe-success';
export const MATERIAL_BASE_TYPE_SUCCESS = 'material/material-base-type-success';
