import {
  MATERIAL_RECIPE_COMPARISON_START,
  MATERIAL_RECIPE_COMPARISON_SUCCESS,
} from './MaterialRecipeComparisonTypes';
import {
  CLEAR,
  ERROR,
  LOADING,
} from 'containers/_Default/Notification/NotificationTypes';
import { getAllRecipesByMaterial } from '../../repository/material';

export const fetchRecipesByMaterial = (materialId: number) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: MATERIAL_RECIPE_COMPARISON_START,
      });

      dispatch({
        type: LOADING,
        payload: {
          isOpen: true,
          message: 'material_recipesloading',
        },
      });

      const response = await getAllRecipesByMaterial(materialId);

      setTimeout(() => {
        dispatch({
          type: CLEAR,
        });
      }, 1000);

      dispatch({
        type: MATERIAL_RECIPE_COMPARISON_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      const message =
        error?.response?.data?.message ||
        error?.response?.data?.detail ||
        error?.response?.data?.title ||
        'error';

      dispatch({
        type: ERROR,
        payload: {
          isOpen: true,
          message,
        },
      });
    }
  };
};
