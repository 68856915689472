import React, { useState } from 'react';
import { Button, Grid, TextField, Typography } from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as marked from 'marked';
import { updateManualInput } from '../../TestResultsActions';

import './ManualInputs.scss';
import { useTranslation } from 'react-i18next';

export const ManualInputs = ({
  fields,
  resultId,
  deviceName,
  canEdit,
  containerClasses = '',
}) => {
  const { control, handleSubmit, setValue, register } = useForm();
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const dispatch = useDispatch();
  const [formFields] = useState(fields as any);

  const { t } = useTranslation('common');

  const onSubmit = async (data) => {
    dispatch(updateManualInput(resultId, data));
    setSubmitDisabled(true);
  };

  // This code is commented out just in case there's a change of heart for the clear feature

  // const clearValues = () => {
  //   const newFields = formFields.map((field) => ({
  //     label: field.label,
  //     value: '',
  //   }));
  //   setFormFields([...newFields]);
  //   newFields.map((field) => setValue(field.label.toString(), ''));
  //   const fields = {};
  //   formFields.map((field) => (fields[field.label] = ''));
  //   reset(fields);
  // };
  return (
    <Grid item container direction={'column'} className={containerClasses}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography variant={'h4'}>
          {t('manualInputs_inputDataFor')} {deviceName || 'device'}
        </Typography>
        {formFields.map((fieldData, index) => {
          const { ref, ...rest } = register(fieldData.name);

          return (
            <Grid item container alignItems={'center'} key={index}>
              <Grid item container xs={4}>
                <div
                  dangerouslySetInnerHTML={{ __html: marked(fieldData.label) }}
                ></div>
              </Grid>
              <Grid item container xs={8}>
                <Controller
                  name={fieldData.name}
                  control={control}
                  defaultValue={fieldData.value || ''}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      {...rest}
                      onChange={(event) => {
                        if (!canEdit) {
                          setValue(fieldData.name, fieldData.value);
                        } else {
                          setValue(fieldData.name, event.target.value);
                          setSubmitDisabled(false);
                        }
                      }}
                      inputRef={ref}
                      inputProps={{
                        name: fieldData.name,
                        defaultValue: fieldData.value || '',
                      }}
                      margin={'dense'}
                      fullWidth
                      variant="standard"
                      disabled={!canEdit}
                    />
                  )}
                />
              </Grid>
            </Grid>
          );
        })}
        {canEdit && (
          <Grid
            item
            container
            justify={'flex-end'}
            spacing={2}
            className={'top-spacing'}
          >
            {/*<Button onClick={clearValues}>Clear</Button>*/}
            <Button
              type={'submit'}
              variant={'contained'}
              color={'primary'}
              className={'submit-button'}
              disabled={submitDisabled}
            >
              {t('submitResults')}
            </Button>
          </Grid>
        )}
      </form>
    </Grid>
  );
};
