import {
  PROJECTEDIT_START,
  PROJECTEDIT_SUCCESS,
  PROJECTEDIT_ERROR,
  PROJECTEDIT_SETMEMBERS,
  PROJECTEDIT_SETTESTS,
  PROJECTEDIT_SAVE_SUCCESS,
  PROJECTEDIT_RESET,
  PROJECTEDIT_DUPLICATE_ID,
  PROJECTEDIT_SETPROJECTDOCUMENTS,
  TESTMATRIX_SELECT_MATERIALS,
  TESTMATRIX_SELECT_STANDARDS,
  PROJECTEDIT_PLAN_SUCCESS,
} from './ProjectTypes';
import { sortNameAlphabetically } from '../../utils/transform-helpers';

const INITIAL_STATE = {
  loading: true,
  error: false,
  saveSuccess: false,
  message: '',
  data: {
    status: 'draft',
    duplicate: false,
  },
  devices: [],
  members: [],
  tests: [],
  duplicateProjectId: false,
  hasResults: false,
  isManualInputOnly: false,
  testMatrix: {
    selectedMaterials: [],
    selectedStandards: [],
  },
};

export default (state: any = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case PROJECTEDIT_START:
      return { ...state, loading: true, error: null };

    case PROJECTEDIT_SAVE_SUCCESS:
      return {
        ...state,
        saveSuccess: true,
        saveMessage: action.payload.message,
        redirectToOverview: action.payload.redirectToOverview,
        loading: false,
      };

    case PROJECTEDIT_PLAN_SUCCESS:
      return {
        ...state,
        saveMessage: action.payload.message,
        redirectToOverview: false,
        loading: false,
      };

    case PROJECTEDIT_SUCCESS:
      const { tests, testMatrix } = action.payload;
      const hasResults = tests.filter((test) => test.hasResults);
      const manualInputOnly = tests.filter((test) => test.manualInputOnly);
      const isManualInputOnly = tests.length === manualInputOnly.length;

      if (testMatrix) {
        sortNameAlphabetically(testMatrix?.selectedStandards);
      }

      return {
        ...state,
        data: {
          hasResults: !!hasResults.length,
          isManualInputOnly: isManualInputOnly,
          ...action.payload,
        },
        testMatrix: testMatrix || INITIAL_STATE.testMatrix,
        loading: false,
      };

    case PROJECTEDIT_ERROR:
      return {
        ...state,
        loading: false,
        redirectToOverview: action.payload.redirectToOverview,
        error: action.payload,
      };

    case PROJECTEDIT_DUPLICATE_ID:
      return {
        ...state,
        duplicateProjectId: action.payload,
      };

    case PROJECTEDIT_SETMEMBERS:
      return { ...state, members: action.payload, loading: false };

    case PROJECTEDIT_SETTESTS:
      return { ...state, tests: action.payload, loading: false };

    case PROJECTEDIT_SETPROJECTDOCUMENTS:
      return { ...state, documents: action.payload, loading: false };

    case PROJECTEDIT_RESET:
      return {
        ...INITIAL_STATE,
        testMatrix: {
          selectedMaterials: [],
          selectedStandards: [],
        },
        loading: false,
      };

    case TESTMATRIX_SELECT_MATERIALS:
      return {
        ...state,
        testMatrix: {
          ...state.testMatrix,
          selectedMaterials: [...action.payload],
        },
      };

    case TESTMATRIX_SELECT_STANDARDS:
      return {
        ...state,
        testMatrix: {
          ...state.testMatrix,
          selectedStandards: [...action.payload],
        },
      };

    default:
      return state;
  }
};
