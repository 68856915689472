import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Grid,
  IconButton,
  LinearProgress,
  Link,
  Typography,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import FolderIcon from '@material-ui/icons/Folder';
import * as filesize from 'file-size';
import { Error, RemoveCircle } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { CustomModal } from '../CustomModal/CustomModal';

import './UploadFiles.scss';
import { getSignedUrl, getExportedFile } from 'repository/testResult';
import { ERROR } from 'containers/_Default/ResetPassword/ResetPasswordTypes';
import { useTranslation } from 'react-i18next';

enum FileStatus {
  READY = 'ready',
  UPLOADED = 'uploaded',
  FAILED = 'failed',
}

export const UploadFiles = ({
  title = 'title',
  maxUploadSize = 31457280,
  uploadCallback = (files) => Promise.resolve([]),
  deleteCallback = (fileName) => {},
  uploadedFiles = [],
  preDefinedPath = '',
}) => {
  const [filesForUpload, setFilesForUpload] = useState([] as any);
  const [alreadyUploaded, setAlreadyUploaded] = useState(uploadedFiles as any);
  const [totalSize, setTotalSize] = useState(0);
  const [uploadingState, setUploadingState] = useState(false);
  const inputFile = useRef<HTMLInputElement>(null);
  const [imagePreview, setImagePreview] = useState(null);
  const dispatch = useDispatch();

  const { t } = useTranslation('uploader');

  useEffect(() => {
    if (!alreadyUploaded?.length && uploadedFiles?.length) {
      setAlreadyUploaded(uploadedFiles);
    }
  }, [uploadedFiles]);

  useEffect(() => {
    setTotalSize(
      filesForUpload.reduce(
        (accumulator, fileEntry) =>
          accumulator +
          ([FileStatus.READY, FileStatus.FAILED].includes(fileEntry.status)
            ? fileEntry.file.size
            : 0),
        0,
      ),
    );
    const updatedFiles = filesForUpload.filter((fileEntry) =>
      [FileStatus.READY, FileStatus.FAILED].includes(fileEntry.status),
    );
    if (!updatedFiles.length) {
      // @ts-ignore
      inputFile.current.value = null;
    }
  }, [filesForUpload]);
  const openFileBrowser = () => {
    // @ts-ignore
    inputFile.current.click();
  };

  const errorCallback = (message) => {
    dispatch({
      type: ERROR,
      payload: {
        isOpen: true,
        message: message,
      },
    });
  };

  const clearFilesForUpload = () => {
    const newValues = filesForUpload.filter(
      (fileEntry) => fileEntry.status === FileStatus.UPLOADED,
    );
    setFilesForUpload(newValues);
  };
  const handleFileUpload = (data) => {
    const newFiles = [] as any;
    if (data.target?.files?.length) {
      data.target.files.forEach((fileEntry) => {
        const fileAlreadySelected =
          filesForUpload.find(
            (fileEntry) => fileEntry.file.name === fileEntry.name,
          ) ||
          alreadyUploaded.find(
            (fileEntry) => fileEntry.originalname === fileEntry.name,
          );
        if (!fileAlreadySelected) {
          newFiles.push({
            file: fileEntry,
            status: FileStatus.READY,
          });
        }
      });
      setFilesForUpload([...filesForUpload, ...newFiles]);
    }
  };

  const removeFileForUpload = (position) => {
    const newValues = [...filesForUpload];
    newValues.splice(position, 1);
    setFilesForUpload(newValues);
  };

  const uploadFiles = async () => {
    try {
      setUploadingState(true);
      const filteredFilesForUpload = filesForUpload
        .filter((entry) =>
          [FileStatus.READY, FileStatus.FAILED].includes(entry.status),
        )
        .map((readyFile) => readyFile.file);

      const failedFiles = await uploadCallback(filteredFilesForUpload);
      const failedFilesMap = {} as any;
      (failedFiles || []).map((file) => {
        failedFilesMap[file] = true;
      });

      const uploadedFiles = filesForUpload.map((fileEntry) => ({
        file: fileEntry.file,
        status: failedFilesMap[fileEntry.file]
          ? FileStatus.FAILED
          : FileStatus.UPLOADED,
      }));
      setFilesForUpload(uploadedFiles);
    } catch (e) {}
    setUploadingState(false);
  };

  const deleteFile = async (fileName, position, newFile = false) => {
    try {
      await deleteCallback(fileName);
      if (newFile) {
        removeFileForUpload(position);
      } else {
        const newValues = [...alreadyUploaded];
        newValues.splice(position, 1);
        setAlreadyUploaded(newValues);
      }
    } catch (e) {}
  };
  const inputRef = React.useRef(null);
  useEffect(() => {
    let holder: any = inputRef.current;
    const dragStarted = (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (!holder.classList.contains('drag-active')) {
        holder.classList.add('drag-active');
      }
    };
    const dragStopped = (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (holder.classList.contains('drag-active')) {
        holder.classList.remove('drag-active');
      }
    };
    const handleDrop = (e) => {
      e.preventDefault();
      e.stopPropagation();
      handleFileUpload({
        target: {
          files: e.dataTransfer?.files,
        },
      });
      dragStopped(e);
    };
    if (holder) {
      holder.addEventListener('dragenter', dragStarted);
      holder.addEventListener('dragover', dragStarted);
      holder.addEventListener('dragleave', dragStopped);
      holder.addEventListener('drop', handleDrop);
      return () => {
        holder.removeEventListener('dragenter', dragStarted);
        holder.removeEventListener('dragover', dragStarted);
        holder.removeEventListener('dragleave', dragStopped);
        holder.removeEventListener('drop', handleDrop);
      };
    }
  }, []);

  return (
    <Grid
      item
      container
      direction={'column'}
      className={'box-holder upload-box'}
      ref={inputRef}
    >
      <Grid>
        <Typography variant={'h3'} className={'upload-title'}>
          {t(title)}
        </Typography>
      </Grid>
      <input
        type="file"
        style={{ display: 'none' }}
        ref={inputFile}
        multiple
        onChange={handleFileUpload}
      />
      <Grid container item justify={'space-between'}>
        <Button
          className={'primary'}
          variant={'outlined'}
          onClick={openFileBrowser}
        >
          <Grid container alignItems={'center'}>
            <FolderIcon />
            <span>{t('addFile')}</span>
          </Grid>
        </Button>
        <Button
          className={'primary'}
          variant={
            uploadingState || totalSize === 0 || totalSize > maxUploadSize
              ? 'contained'
              : 'outlined'
          }
          disabled={
            uploadingState || totalSize === 0 || totalSize > maxUploadSize
          }
          onClick={uploadFiles}
        >
          <Grid container alignItems={'center'}>
            {!uploadingState ? (
              <span>{t('upload')}</span>
            ) : (
              <span>{t('uploading')}</span>
            )}
          </Grid>
        </Button>
      </Grid>
      <Grid className={'file-browse'}>
        {alreadyUploaded?.map((file, index) => {
          return (
            <FileRow
              file={file}
              newUpload={false}
              index={index}
              removeFromUpload={removeFileForUpload}
              deleteUploaded={deleteFile}
              status={FileStatus.UPLOADED}
              setImagePreview={setImagePreview}
              imagePreview={imagePreview}
              errorCallback={errorCallback}
              t={t}
            />
          );
        })}
        {filesForUpload.map((entry, index) => {
          return (
            <FileRow
              file={entry.file}
              newUpload={true}
              index={index}
              removeFromUpload={removeFileForUpload}
              deleteUploaded={deleteFile}
              preDefinedPath={preDefinedPath}
              status={entry.status}
              setImagePreview={setImagePreview}
              imagePreview={imagePreview}
              errorCallback={errorCallback}
              t={t}
            />
          );
        })}
      </Grid>
      <Grid container item className={'action-holder'}>
        <Grid container item direction={'column'}>
          <Grid
            item
            container
            alignItems={'center'}
            justify={'flex-end'}
            className={'upload-button-list'}
          >
            {totalSize > 0 && (
              <Button
                className={'primary'}
                variant={'contained'}
                onClick={clearFilesForUpload}
              >
                {t('clear')}
              </Button>
            )}
          </Grid>

          <Typography variant={'body2'} style={{ alignSelf: 'flex-end' }}>
            {filesize(totalSize).human('jedec')}
            {' / '}
            {filesize(maxUploadSize).human('jedec')}
          </Typography>
        </Grid>
      </Grid>
      {uploadingState ? <LinearProgress className="upload-progress" /> : null}
    </Grid>
  );
};

const FileRow = ({
  file,
  newUpload,
  removeFromUpload,
  deleteUploaded,
  preDefinedPath = '',
  index,
  status,
  setImagePreview,
  imagePreview,
  errorCallback = (message) => {},
  t,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [signedUrl, setSignedUrl] = useState('');

  useEffect(() => {
    createUrl(file);
    if (file.path?.startsWith('/')) {
      file.path = file.path?.replace(/\//, '');
    }
    const getDownloadUrl = async () => {
      try {
        const { data: signedUrl } = await getSignedUrl(
          `${process.env.REACT_APP_S3_BUCKET_UPLOADS}`,
          file.path || preDefinedPath + file.name,
        );

        setSignedUrl(signedUrl);
      } catch (error) {
        return;
      }
    };

    getDownloadUrl();
  }, [file, status, modalOpen]);

  const handleFileDownload = async () => {
    try {
      if (file.path?.startsWith('/')) {
        file.path = file.path?.replace(/\//, '');
      }
      const { data } = await getSignedUrl(
        `${process.env.REACT_APP_S3_BUCKET_UPLOADS}`,
        file.path || preDefinedPath + file.name,
      );
      setSignedUrl(data);
      await getExportedFile(data);
    } catch (error) {
      errorCallback('Error downloading file');
    }
  };

  const createUrl = async (file) => {
    if (file) {
      if (status === FileStatus.UPLOADED && file.path) {
        setImagePreview(signedUrl);
      } else if (status === FileStatus.READY) {
        setImagePreview(URL.createObjectURL(file));
      }
    }
  };
  return (
    <Grid container className={'file-row'}>
      <Grid item container direction={'column'} xs={10}>
        <Grid container alignItems={'center'} spacing={2}>
          <Grid item>
            <Typography variant={'body2'}>
              {file.name || file.originalname}
              {status === FileStatus.FAILED && <Error />}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container>
          <Typography variant={'body1'}>
            {filesize(parseInt(file.size)).human('jedec')}
          </Typography>
        </Grid>
        <Grid>
          {newUpload && (
            <Typography variant={'body2'}>
              {status === FileStatus.READY && t('readyToUpload')}
              {status === FileStatus.UPLOADED &&
                newUpload &&
                t('uploadedSuccess')}
            </Typography>
          )}

          {file.type?.match('image.*') || file.mimetype?.match('image.*') ? (
            <Link onClick={() => setModalOpen(true)} className="preview-link">
              {t('preview')}
            </Link>
          ) : null}
        </Grid>
      </Grid>
      <Grid item container direction={'column'} xs={2}>
        <Grid item container justify={'flex-end'}>
          {status === FileStatus.UPLOADED && (
            <IconButton color="secondary" onClick={() => handleFileDownload()}>
              <CloudDownloadIcon />
            </IconButton>
          )}
          <IconButton
            color="secondary"
            onClick={() =>
              [FileStatus.READY, FileStatus.FAILED].includes(status)
                ? removeFromUpload(index)
                : deleteUploaded(
                    file.name || file.originalname,
                    index,
                    newUpload,
                  )
            }
          >
            {[FileStatus.READY, FileStatus.FAILED].includes(status) ? (
              <RemoveCircle />
            ) : (
              <DeleteIcon />
            )}
          </IconButton>
        </Grid>
      </Grid>
      <CustomModal
        setIsOpen={setModalOpen}
        isOpen={modalOpen}
        disableActions
        disableContentText
        data={{
          dialogTitle: `${file.originalname || t('preview')}`,
        }}
      >
        <img src={imagePreview} alt="preview" />
      </CustomModal>
    </Grid>
  );
};
