import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { Controller, useForm, useFormContext } from 'react-hook-form';
import { InputError } from '../../../../components';
import { getSingleResultTable } from 'repository/testResult';
import { useTranslation } from 'react-i18next';

export enum ResultExclusionReason {
  BAD_MATERIAL = 'badMaterial',
  BAD_TEST = 'badTest',
  OTHER = 'other',
}

const ResultExcludeModal = () => {
  const { tableId, resultTableId, editMode } = useSelector(
    (state) => state.projectData.activeResultTable,
  );
  const [reason, setReason] = useState('');
  const [value, setInputValue] = useState('');

  const { control, register, setValue, formState } = useFormContext();

  const { t } = useTranslation('projectData');

  useEffect(() => {
    if (editMode) {
      const fetchSingleTable = async () => {
        const { data } = await getSingleResultTable({
          resultId: tableId,
          resultTableId,
        });

        const response = data?.comments?.find(
          (comment) => comment?.type === 'exclude',
        );

        if (response) {
          const { comment, _id } = response;
          setValue('commentId', _id);

          setReason(data.resultExclusionReason);
          setValue('reason', data?.resultExclusionReason);

          setInputValue(comment);
          setValue('comment', comment);
        }
      };

      fetchSingleTable();
    } else return;
  }, [tableId, resultTableId]);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setReason(event.target.value as ResultExclusionReason);
  };
  return (
    <Grid item container xs={12}>
      <InputLabel htmlFor={'exclude-result-select'}>
        {t('excludeResultModalTitle')}
      </InputLabel>
      <Controller
        name={'reason'}
        defaultValue={''}
        rules={{ required: true }}
        control={control}
        render={({ field: { onChange, value } }) => (
          <Grid item container xs={12} alignItems={'center'}>
            <Select
              labelId="exclude-result-label"
              id="exclude-result-select"
              value={reason}
              onChange={(event, value) => {
                handleChange(event);
                setValue('reason', event.target.value);
              }}
              label={t('excludeResultModalTitle')}
              fullWidth={true}
            >
              <MenuItem value={ResultExclusionReason.OTHER}>
                {t('excludeResultModalOption')}
              </MenuItem>
              <MenuItem value={ResultExclusionReason.BAD_TEST}>
                {t('resultView_problemWithTest')}
              </MenuItem>
              <MenuItem value={ResultExclusionReason.BAD_MATERIAL}>
                {t('resultView_problemWithMaterial')}
              </MenuItem>
            </Select>
            {formState.errors['reason'] && (
              <InputError message={t('requiredField')} />
            )}
          </Grid>
        )}
      />
      <InputLabel
        htmlFor={'exclude-reason-comment'}
        style={{ marginTop: '30px' }}
      >
        {t('project:comment')}
      </InputLabel>
      <Controller
        name={'comment'}
        rules={{ required: true }}
        control={control}
        defaultValue={editMode ? value : ''}
        render={({ field: { onChange, value } }) => {
          const { ref, ...rest } = register('comment', {
            required: true,
          });
          return (
            <TextField
              onChange={(event) => {
                setInputValue(event.target.value);
                setValue('comment', event.target.value);
              }}
              inputProps={{
                name: 'comment',
                defaultValue: editMode ? value : '',
                maxLength: 255,
              }}
              margin={'dense'}
              fullWidth
              id={'exclude-reason-comment'}
              multiline
              rowsMax={5}
              variant="standard"
            />
          );
        }}
      />
      {formState.errors['comment'] && (
        <InputError message={t('requiredField')} />
      )}
      <span style={{ alignSelf: 'flex-end', marginLeft: 'auto' }}>
        {value.length} / 255
      </span>
    </Grid>
  );
};

export default ResultExcludeModal;
