import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import { FormType } from '../DocumentForm/DocumentForm';
import { getFieldFromType } from 'components/Forms/FieldTypes';
import DocumentCommentModal from './DocumentCommentModal';

export enum DocumentName {
  PI = 'document_pi',
  CLEANING = 'document_cleaning',
  RELEASE = 'document_release',
  PSS = 'document_pss',
}

interface DocumentModal {
  isOpen: boolean;
  closeModal: (event: object, reason?: string) => void;
  closeOKModal: (event: object, reason?: string) => void;
  submitFormFunction: (documentId, data: any) => any;
  completeAction: (documentId, data: any) => any;
  title?: string;
  disableActions: boolean;
  maxWidth;
  hideDivider?: boolean;
  hideSubmit?: boolean;
  hideCancel?: boolean;
  submitButtonText?: string;
  cancelButtonText?: string;
  disabled?: boolean;
  t: any;
  submitComment: Function;
}

const DocumentModal: React.FunctionComponent<DocumentModal> = ({
  isOpen = false,
  closeModal = () => {},
  closeOKModal = () => {},
  submitFormFunction = () => {},
  completeAction = () => {},
  title,
  disableActions = false,
  maxWidth = 'xl',
  hideDivider = false,
  hideSubmit = false,
  hideCancel = false,
  submitButtonText = false,
  cancelButtonText = false,
  children,
  disabled = false,
  t,
  submitComment = () => {},
}) => {
  const document = useSelector((state) => state.projectData.activeDocument);
  const methods = useForm();
  const documentType = document?.documentConfig?.configType;
  const documentName = document.documentConfig?.name;
  const config = JSON.parse(localStorage.getItem('config') || '{}');
  const hasCompleteAction = document.documentConfig?.hasCompleteAction;

  const [canSign, setCanSign] = useState(false);

  useEffect(() => {
    const data = { ...methods.getValues() };
    if (hasCompleteAction) {
      if (documentName === DocumentName.CLEANING) {
        setCanSign(
          !Object.values(data).includes(false) &&
            !Object.values(data).includes(undefined) &&
            methods.getValues('performedBy') !== '',
        );
      } else if (documentName === DocumentName.PI) {
        setCanSign(methods.getValues('performedBy') !== '');
      } else {
        setCanSign(
          !Object.values(data).includes(false) &&
            !Object.values(data).includes(undefined),
        );
      }
    }
  }, [methods.getValues(), methods.watch()]);

  const [comment, setComment] = useState('' as any);
  const [clearComment, setClearComment] = useState(false as any);

  useEffect(() => {
    methods.reset({});
  }, [document?._id]);

  return (
    <Dialog
      open={isOpen}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      fullWidth
      maxWidth="xl"
    >
      <FormProvider {...methods}>
        <form>
          {title !== '' ? (
            <DialogTitle className={hideDivider ? 'dialog-title' : ''}>
              <Grid
                item
                container
                justify={'space-between'}
                alignItems={'center'}
              >
                <Typography variant={'h4'}>{title}</Typography>{' '}
                <IconButton onClick={closeModal}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </DialogTitle>
          ) : null}
          <DialogContent className="dialog-content">{children}</DialogContent>
          {!disableActions && (
            <DialogActions style={{ marginTop: 20 }}>
              <Grid container xs={12} justify="flex-start" spacing={3}>
                {hasCompleteAction &&
                ![DocumentName.RELEASE].includes(documentName) ? (
                  <Grid item xs={4}>
                    {getFieldFromType(
                      methods,
                      {
                        fieldName: 'performedBy',
                        label: 'projectDocumentation:performedBy',
                        type: 'text',
                        required: true,
                        className: 'login-input',
                        span: 12,
                        disabled: document?.completed,
                      } as any,
                      `${document?._id}-${document?.data?.performedBy}`,
                      document?.data,
                      t,
                      document?.completed,
                    )}
                  </Grid>
                ) : null}

                <Grid
                  item
                  container
                  xs={![DocumentName.PI].includes(documentName) ? 12 : 7}
                  justify="flex-end"
                  alignItems="flex-end"
                >
                  {document?.completed ? (
                    <Grid item xs={12}>
                      <Typography variant="h4">{`${t(
                        'projectDocumentation:completedBy',
                      )}: ${document?.completedBy?.email}`}</Typography>
                      <Typography variant="h4">
                        (
                        {moment(document?.updatedAt)
                          .tz(config.region)
                          .format('DD/MM/yyyy HH:mm')}
                        )
                      </Typography>
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
              {!hideCancel && (
                <Button onClick={closeModal} variant="contained">
                  {cancelButtonText ? cancelButtonText : t('common:cancel')}
                </Button>
              )}
              {hasCompleteAction && (
                <Button
                  onClick={async (event) => {
                    event.preventDefault();
                    const data = methods.getValues();
                    delete data.comment;
                    try {
                      await completeAction(
                        document?._id,
                        documentType === FormType.TABLE
                          ? {
                              results: Object.values(data)
                                .filter((item) => !item.deleted)
                                .map((item) => {
                                  delete item.generatedId;
                                  return item;
                                }),
                            }
                          : { data: data },
                      );
                      closeOKModal(event, '');
                      methods.reset({});
                    } catch (e) {}
                  }}
                  variant="contained"
                  disabled={document?.completed || !canSign}
                >
                  {t('projectDocumentation:complete')}
                </Button>
              )}
              {!hideSubmit && (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={async (event) => {
                    event.preventDefault();
                    const data = methods.getValues();
                    delete data.comment;
                    try {
                      await submitFormFunction(
                        document?._id,
                        documentType === FormType.TABLE
                          ? {
                              results: Object.values(data)
                                .filter((item) => !item.deleted && item)
                                .map((item) => {
                                  delete item.generatedId;
                                  return item;
                                }),
                            }
                          : { data: data },
                      );
                      closeOKModal(event, '');
                      methods.reset({});
                    } catch (e) {}
                  }}
                  disabled={document?.completed || disabled}
                  type="submit"
                >
                  {t(submitButtonText ? submitButtonText : 'common:submit')}
                </Button>
              )}
            </DialogActions>
          )}
        </form>
        <Grid item xs={12} className="document-comments">
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography variant="h3">{t('common:comments')}</Typography>
            </Grid>
            <Grid item xs={12}>
              <DocumentCommentModal
                onInputChangeAction={(comment) => setComment(comment)}
                setClear={setClearComment}
                clear={clearComment}
              />
              <Grid
                container
                item
                sm={12}
                justify="flex-end"
                className="document-comments-submit"
              >
                <Button
                  color="primary"
                  variant="contained"
                  disabled={!comment || comment?.trim()?.length === 0}
                  onClick={() => {
                    submitComment(comment);
                    setComment('');
                    setClearComment(true);
                  }}
                >
                  {t('results:submitComment')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FormProvider>
    </Dialog>
  );
};

export default DocumentModal;
